<template>
  <div v-if="show" class="modale">
      <div class="modal_content">
        <h2>Учет времени</h2>
        <form action="" @submit.prevent="submitTime">
          <div class="form-control">
            <label for="start_time">Дата начала</label>
            <input type="datetime-local" id="start_time" v-model="startTime"/>
          </div>
          <div class="form-control">
            <label for="finish_time">Дата окончания</label>
            <input type="datetime-local" id="finish_time" v-model="finishTime"/>
          </div>
          <div class="form-control checkbox">
            <input type="checkbox" id="sicked" v-model="sicked" @change="updateCheckboxValues('sicked')"/>
            <label for="sick">Больничный</label>
          </div>
          <div class="form-control checkbox">
            <input type="checkbox" id="begged" v-model="begged" @change="updateCheckboxValues('begged')"/>
            <label for="begged">Отпросился</label>
          </div>
          <div class="form-control checkbox">
            <input type="checkbox" id="skipped" v-model="skipped" @change="updateCheckboxValues('skipped')"/>
            <label for="skipped">Пропустил</label>
          </div>
          <div class="form-actions">
            <button class="btn cancel" @click="hideWindow">Отмена</button>
            <button class="btn primary">Сохранить</button>
          </div>
        </form>
      </div>
    </div>
</template>
<script>
/* eslint-disable */
import axios from 'axios'
import { Header } from '@/api/index'
export default {
  data () {
    return {
      startTime: null,
      finishTime: null,
      sicked: false,
      begged: false,
      skipped: false
    }
  },
  props: {
    show: { type: Boolean, default: false },
    id: { type: String, required: true }
  },
  computed: {
    checkboxValues () {
      return {
      sicked: this.sicked,
      begged: this.begged,
      skipped: this.skipped
      }
  }
},
  watch: {
    show (value) {
      if (value) {
        axios.get(`${process.env.VUE_APP_SERVER_URL}work_time/${this.id}`, {
          headers: Header.auth
        }).then(response => {
          const worktime = response.data
          this.startTime = worktime.started_at ? worktime.started_at.slice(0, 16) : ''
          this.finishTime = worktime.finished_at ? worktime.finished_at.slice(0, 16) : ''
          if (worktime.status === 'sicked') {
            this.sicked = true
          } else if (worktime.status === 'begged') {
            this.begged = true
          } else if (worktime.status === 'skipped') {
            this.skipped = true
          }
        })
      }
    }
  },
  methods: {
    hideWindow () {
      this.$emit('update:show', false)
    },
    async submitTime () {
      try {
        await axios.patch(`${process.env.VUE_APP_SERVER_URL}work_time/${this.id}`, {
          started_at: this.startTime,
          finished_at: this.finishTime,
          status: this.sicked ? 'sicked' : this.begged ? 'begged' : this.skipped ? 'skipped' : 'worked'
        }, {
          headers: Header.auth
        })
      } catch (error) {
      }
      this.startTime = null
      this.finishTime = null
      this.sicked = false
      this.begged = false
      this.skipped = false
      this.$parent.fetchWorktime()
      this.hideWindow()
    },
    updateCheckboxValues (name) {
      Object.keys(this.checkboxValues).forEach(key => {
        if (key !== name) {
          this[key] = false
        }
      })
    }
  }
}
</script>

<style scoped>
.modale {
  position:fixed;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 100%;
  color:white;
  font-family: mulish;
}
.modal_content{
  position: relative;
  display:flex;
  left:700px;
  top:200px;
  align-items: center;
  flex-direction: column;
  border-radius: 25px;
  width:400px;
  height: 500px;
  background-color:rgb(46, 45, 45);
}
.form-control{
  display:flex;
  width:100%;
  justify-content: space-between;
}
</style>
