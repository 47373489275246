<template>
<div class="list">
  <div class="field">
    <input id="search" class="search" type="text" placeholder="Поиск..." v-model="username">
    <select id="select" class="select" @click="get_all_employees" v-model="select">
      <option value="false">Обычные сотрудники</option>
      <option value="true">Заблокированные</option>
      <option value="all">Все сотрудники</option>
    </select>
    <button id="add" type="button" class="btnAdd" @click="show_add_window(true)">Cоздать сотрудника</button>
  </div>
  <div class="list__title">
    <div class="list__titleItem">Фото</div>
    <div class="list__titleItem">Ф И О</div>
    <div class="list__titleItem">Действия</div>
  </div>
  <div class="scroll">
    <a class="row" v-for="item in employees.filter((employee) => employee.user_name.toLowerCase().includes(this.username.trim().toLowerCase()))" :key="item.id">
      <div class="usersInfo">
        <div class="usersInfo_line">
          <div class="col">
            <img id="photo" :src="url(item.user_photo_url)" alt="photo"/>
          </div>
          <div class="col">{{ item.user_name }} {{ item.user_surname }} {{ item.user_patronymic }}</div>
          <div class="btns">
            <button v-if="!item.is_blocked" id="bord_red" class="btn" @click="show_state_window(true, item)"></button>
            <button v-if="!item.is_blocked" id="bord_green" class="btn" @click="submit_employee('change', item)"></button>
            <button v-else id="bord_lite" class="btn" @click="show_state_window(true, item)"></button>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>

<!-- Окно создания сотрудника -->
<div v-if="add_window" class="add add_container">
  <div class="add_body">
    <div class="add_close">
      <img id="close_1" src="@/assets/images/close.png" alt="close" @click="show_add_window(false)"/>
    </div>
    <p>Создание сотрудника</p>
    <div id="form" class="add_form">
      <div>
        <label>Пользователь:</label>
        <select class="add_select" v-model="employee.user_id">
          <option value="" disabled selected>Выберите из списка</option>
          <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }} - {{ user.surname }} - {{ user.patronymic }}</option>
        </select>
      </div>
      <div>
        <label>Отдел:</label>
        <select class="add_select" v-model="employee.departament_id">
          <option value="" disabled selected>Выберите из списка</option>
          <option v-for="departament in departaments" :key="departament.id" :value="departament.id">{{ departament.title }}</option>
        </select>
      </div>
    </div>
    <button id="add" class="add_btn" @click="submit_employee('add', employee)">Создать</button>
  </div>
</div>

<!-- Окно удаления / восстановления сотрудника -->
<div v-if="state_window" class="state state_container">
  <div class="state_body">
    <div class="state_close">
      <img id="close_1" src="@/assets/images/close.png" @click="show_state_window(false)">
    </div>
    <p v-if="!temp_employee.is_blocked">Удалить сотрудника</p>
    <p v-else>Восстановить сотрудника</p>
    <div class="state_btns">
      <button v-if="!temp_employee.is_blocked" id="delete" @click="change_employee(temp_employee)">Удалить</button>
      <button v-else id="recover" @click="change_employee(temp_employee)">Восстановить</button>
      <button id="close_2" @click="show_state_window(false)">Закрыть</button>
    </div>
  </div>
</div>
</template>

<script>
import { getAllEmployees, getAllUsers, getAllDepartaments } from '@/api/get'
import { postAddEmployee } from '@/api/post'
import { patchBlockEmployee, patchRecoverEmployee } from '@/api/patch'

export default {
  data () {
    return {
      add_window: false,
      state_window: false,
      username: '',
      select: 'false',
      employees: [],
      users: [],
      departaments: [],
      temp_employee: {},
      employee: { user_id: '', departament_id: '' }
    }
  },
  methods: {
    show_add_window (value) {
      this.add_window = value
      if (value) {
        this.get_all_users()
        this.get_all_departaments()
      }
    },
    show_state_window (value, employee = {}) {
      this.state_window = value
      this.temp_employee = employee
    },
    url (url) { return `${process.env.VUE_APP_SERVER_URL}/${url}` },
    get_all_employees () {
      getAllEmployees(this.select).then((response) => {
        this.employees = response
      })
    },
    get_all_users () {
      getAllUsers('false').then((response) => {
        this.users = response
      })
    },
    get_all_departaments () {
      getAllDepartaments('false').then((response) => {
        this.departaments = response
      })
    },
    submit_employee (value, employee) {
      if (value === 'add') {
        postAddEmployee(employee).then((value) => {
          if (value) {
            this.get_all_employees()
            for (const key in this.employee) this.employee[key] = ''
            this.show_add_window(false)
          }
        })
      }
      if (value === 'change') alert('Пока еще не реализовано на бэке...')
    },
    change_employee (employee) {
      if (employee.is_blocked) {
        patchRecoverEmployee(employee.id).then((response) => {
          if (response) {
            this.get_all_employees()
            this.show_state_window(false)
          }
        })
      } else {
        patchBlockEmployee(employee.id).then((response) => {
          if (response) {
            this.get_all_employees()
            this.show_state_window(false)
          }
        })
      }
    }
  },
  mounted () {
    this.get_all_employees()
  }
}
</script>
