<template>
    <div>
      <form>
        <h2>Учет времени</h2>
        <div class="form-group">
          <!-- <label for="timeInput">Время (часы:минуты)</label> -->
          {{ formattedDate }}
          <!-- <input type="time" id="timeInput" v-model="time"> -->
        </div>
        <!-- <div class="form-group">
          <label for="taskInput">Задача</label>
          <textarea id="taskInput" v-model="task"></textarea>
        </div> -->
        <button type="submit" @click.prevent="saveTime">Сохранить</button>
        <button type="button" @click.prevent="cancel">Отмена</button>
      </form>
    </div>
  </template>

<script>
export default {
  props: {
    formattedDate: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.dateSelected = this.date
  },
  data () {
    return {
      dateSelected: '',
      time: '',
      task: ''
    }
  },
  methods: {
    saveTime () {
      if (this.time !== '' && this.task !== '') {
        const data = {
          date: this.dateSelected,
          time: this.time,
          task: this.task
        }
        this.$emit('addTime', data)
        this.cancel()
      } else {
        alert('Заполните все поля')
      }
    },
    cancel () {
      this.$emit('close')
    }

  }
}
</script>

  <style scoped>
    form {
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 10px;
      width: 400px;
      margin: 50px auto;
      font-family: Arial, sans-serif;
    }

    h2 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .form-group {
      margin-bottom: 10px;
    }

    label {
      display: block;
      margin-bottom: 5px;
    }

    textarea,
    input[type='time'] {
      width: 100%;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #ccc;
      font-size: 16px;
    }

    button {
      background-color: #4CAF50; /* Green */
      border: none;
      color: white;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      margin-right: 10px;
    }

    button:last-of-type {
      background-color: #ccc;
    }
  </style>
