<template>
<div>
  <span class="error">{{ message }}</span>
  <textarea :id="id" :type="type" :value="value" :class="style" placeholder="Введите текст"></textarea>
</div>
</template>

<script>
export default {
  name: 'textarea-comp',
  props: {
    message: { type: String, default: '' },
    id: { type: String, default: 'none' },
    type: { type: String, default: 'text' },
    value: { type: String, default: 'none' },
    style: { type: String, default: '' }
  }
}
</script>
