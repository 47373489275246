<template>
  <div class="container">
    <div class="list">
      <button class="btnAdd" @click="changeVisibleCreateModal">Создать аналитику</button>
      <!-- modal -->
      <div v-if="visibleCreateModal" class="add add_container">
        <div class="add_body">
          <div class="add_close">
            <img id="close_1" src="@/assets/images/close.png" alt="close" @click="changeVisibleCreateModal"/>
          </div>
          <p>Создание аналитики</p>
          <div id="form" class="add_form">
            <div>
              <label>За период с:</label>
              <div>
                <span class="error">{{ message }}</span>
                <input type="date" v-model="createAnalyticObject.from_date" placeholder="Введите значение">
              </div>
            </div>
            <div>
              <label>По:</label>
              <div>
                <span class="error">{{ message }}</span>
                <input type="date" v-model="createAnalyticObject.to_date" placeholder="Введите значение">
              </div>
            </div>
            <div>
              <label>Пользователи, выводимые в отчете:</label>
              <div>
                <span class="error">{{ message }}</span>
                <select v-model="createAnalyticObject.list_users" multiple>
                  <option v-for="user in allUsers" :key="user.id" :value="user.id">{{ user.surname }} {{ user.name }} {{ user.patronymic }}</option>
                </select>
              </div>
            </div>
          </div>
          <button id="add" class="add_btn" @click="sendAnalytic">Отправить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllUsers } from '@/api/get'
import { postAnalytic } from '@/api/post'

export default {
  data () {
    return {
      visibleCreateModal: false,
      createAnalyticObject: {
        from_date: null,
        to_date: null,
        list_users: []
      },
      allUsers: null
    }
  },
  methods: {
    changeVisibleCreateModal () {
      getAllUsers('all').then(response => {
        this.allUsers = response
      })
      this.visibleCreateModal = !this.visibleCreateModal
    },
    sendAnalytic () {
      postAnalytic(this.createAnalyticObject).then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.href = downloadUrl
        link.download = 'analytic.xlsx'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }
  }
}
</script>

<style scoped>
.input_search {
  margin-top: 11px;
  margin-left: 18px;
  padding: 10px;
  width: 250px;
  height: 40px;
  background: #d9d9d9;
  border-style: solid;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.list {
  position: relative;
  overflow-y: auto;
  width: 95%;
  height: 100vh;
  margin: 0 auto;
  flex: 1 0 auto;
  padding: 10px;
  padding-top: 0;
  margin-top: 20px;
}

.list__title {
  margin-top: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  font-family: "Mulish";
  color: #ffffff;
  display: flex;
  width: 85%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.formes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: unset;
  justify-content: space-around;
  /* background-color: rgb(54, 117, 117); */
  width: 500px;
  /* height: 50vh; */
  overflow-y: scroll;
}

.formes::-webkit-scrollbar {
  -webkit-appearance: none;
}

.formes::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: 1px solid #ecc22c;
  background-color: #6e6f77;
}

.form {
  /* background-color: aquamarine; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.btnAdd {
  cursor: pointer;
  width: 170px;
  height: 39px;
  font-family: "Monsterrat";
  font-weight: 700;
  font-size: 14px;
  border: none;
  color: black;
  margin-top: 25px;
  margin-left: 18px;
  border: 0;
  background: #ecc22c;
  border-radius: 5.53226px;
}
/*начало стили для input */
.input {
  margin-top: 5px;
  display: block;
  height: 38px;
  width: 200px;
  padding: 17px 20px;
  background: none;
  border-width: 0px 0px 3px 3px;
  border-style: solid;
  border-color: #ecc22c;
  border-radius: 5px;
  font-family: "Monsterrat";
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}
::-webkit-input-placeholder {
  color: #ffffff8b;
}
/*конец стили для input */
.btn_dowload {
  border: 1px solid #000000;
}
.popup_block_format {
  background-color: #bebebe;
  top: 5px;
  left: 15px;
  position: relative;
  border-radius: 8px;
  border-top: 4px solid #ecc22c;
  width: 600px;
  height: 160px;
  font-size: 20px;
  font-family: "Monsterrat";
  display: block;
  cursor: pointer;
}

.position_btn {
  display: flex;
  margin-left: 180px;
  margin-top: -32px;
}
/* стилизация чекбокса */
.checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox.style-c input {
  position: relative;
  left: 10%;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox.style-c input:checked ~ .checkbox__checkmark {
  background-color: #f7cb15;
}

.checkbox.style-c input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}

.checkbox.style-c:hover input ~ .checkbox__checkmark {
  background-color: #eee;
}

.checkbox.style-c:hover input:checked ~ .checkbox__checkmark {
  background-color: #f7cb15;
}

.checkbox.style-c .checkbox__checkmark {
  position: absolute;
  top: 0px;
  height: 20px;
  width: 20px;
  background-color: #eee;
  transition: background-color 0.25s ease;
  border-radius: 4px;
}

.checkbox.style-c .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #333;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}

.checkbox.style-c .checkbox__body {
  color: #333;
  line-height: 1.4;
  font-size: 16px;
}

/* конец стилизации чекбокса */
.btn {
  cursor: pointer;
  width: 170px;
  height: 39px;
  font-family: "Monsterrat";
  font-weight: 700;
  font-size: 14px;
  border: none;
  color: black;
  margin-top: -7px;
  margin-left: 18px;
  border: 0;
  background: #ecc22c;
  border-radius: 5.53226px;
}

.scroll {
  /* margin-top: -53px; */
  overflow-y: auto;
  height: 84%;
}

.col {
  width: auto;
  text-align: left;
  color: #ffffff;
}

.col img {
  width: 56px;
  height: 56px;
  border-radius: 30px;
}
.usersInfo {
  display: flex;
  width: 100%;
  height: 70px;
  background-color: #bebebe;
  border-top: 3px solid #ecc22c;
  border-radius: 5px;
  align-items: center;
  margin-top: 20px;
}
.usersInfo_line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
