<template>
    <div v-if="show" @click.stop="hideWindow">
      <div class="body">
        <div class="add_employer" @click.stop>
          <input
            type="text"
            v-model="searchInput"
            @input="searchUsers"
            @keyup="searchUsers"
            placeholder="Поиск сотрудников"
          />
          <div class="list">
            <ul v-if="searchResults.length > 0">
              <li
                v-for="user in searchResults"
                :key="user.id"
                @click="selectUser(user)"
              >
                {{ user.surname }} {{ user.name }} {{ user.patronymic }}
              </li>
            </ul>
          </div>
          <button @click="showConfirmationWindow">Добавить сотрудника</button>
        </div>
      </div>
      <ConfirmationWindow
        :show="confirmationWindow"
        @update:show="confirmationWindow = $event"
        @confirm="addEmployee"
      />
    </div>
  </template>

<script>
import { Header } from '@/api/index'
import axios from 'axios'
import ConfirmationWindow from './ConfirmationWindow.vue'

export default {
  components: {
    ConfirmationWindow
  },
  props: {
    show: { type: Boolean, default: false },
    departamentId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      searchInput: '',
      users: [],
      searchResults: [],
      selectedUserId: null,
      confirmationWindow: false
    }
  },
  mounted () {
    this.showList()
  },
  methods: {
    hideWindow () {
      this.$emit('update:show', false)
    },
    showList () {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}users/`)
        .then((response) => {
          this.users = response.data
          this.searchResults = this.users
        })
        .catch((error) => {
          console.log(error)
        })
    },
    searchUsers () {
      if (this.searchInput.length > 0) {
        this.searchResults = this.users.filter((user) => {
          return (
            user.surname.toLowerCase().includes(this.searchInput.toLowerCase()) ||
              user.name.toLowerCase().includes(this.searchInput.toLowerCase()) ||
              user.patronymic.toLowerCase().includes(this.searchInput.toLowerCase())
          )
        })
      } else {
        this.searchResults = this.users
      }
    },
    selectUser (user) {
      this.selectedUserId = user.id
      this.searchInput = user.surname + ' ' + user.name + ' ' + user.patronymic
      this.searchResults = []
    },
    showConfirmationWindow () {
      this.confirmationWindow = true
    },
    addEmployee () {
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}employees/`, {
          user_id: this.selectedUserId,
          departament_id: this.departamentId
        }, { headers: Header.auth })
        .then((response) => {
          console.log(response.data)
          this.$emit('employeeAdded')
          this.$emit('update:show', false)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
.body {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 30, 30, 0.267);
  top: 0;
  left: 0;
  font-family: Arial, sans-serif;
}
.list{
    width:100%;
    height: 270px;
    overflow-y:scroll;
}

.add_employer {
  position: fixed;
  width: 500px;
  height: 400px;
  margin-left: -200px;
  background-color: rgb(34, 30, 30);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 40;
}

.add_employer input[type="text"] {
  position:absolute;
  top:0;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  background-color: #fff;
  color: #111;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0;
  text-align: left;
  list-style-type: none;
  color:white;
  width: 100%;
}

li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

li:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

button {
  position:absolute;
  bottom: 0px;
  border: none;
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #4CAF50;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

button:hover {
  background-color: #3e8e41;
}

.add_employer input[type="text"]::placeholder {
  color: #bbb;
}

.selected {
  background-color: rgba(255, 255, 0, 0.2);
}

.scroll::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #000;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* для того, чтобы скроллбар был виден на всех браузерах */
.list::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

.list::-webkit-scrollbar-thumb {
  background-color: #000;
}

.list::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
</style>
