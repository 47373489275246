<template>
  <div v-if="show" class="user-selector">
    <div class="body">
      <div class="create_task">
        <div class="create_task_logo">
          <a><img src="@/assets/images/Workspace/NewTask.png"></a>
          <p>Редактировать задачу</p>
        </div>
        <a><img src="@/assets/images/Workspace/CloseWhite.png" @click="hideWindow"></a>
      </div>
      <div class="container">
        <div class="input-container">
          <div class="input-container" style="position: relative; z-index: 1;"></div>
          <form id="form" class="formes" @submit.prevent="submit">
            <div class="input_wrapper">
              <p>Исполнитель</p>
              <input
                type="text"
                v-model="selectedUser"
                placeholder="Выберите пользователя"
                @keyup="searchUser"
                @focus="getUsers"
              />
              <ul class="user-list" style="max-height: 200px;  overflow-y: auto;">
                <li v-for="user in filteredUsers" :key="user.id" @click="selectUser(user)">
                  {{ user.name }} {{ user.surname }} {{ user.patronymic }}
                </li>
              </ul>
            </div>
            <div class="input_wrapper">
              <p>Название</p>
              <input type="text" v-model="taskTitle" placeholder="Название задания">
            </div>
            <div class="input_wrapper">
              <p>Тип задачи</p>
              <select v-model="selectedTypeID" class="typeselect">
                <option value="" disabled selected>Выберите тип</option>
                <option v-for="type in typeList" :key="type.id" :value="type.id">{{ type.title }}</option>
              </select>

            </div>
            <div class="input_wrapper">
              <p>Приоритет задачи</p>
              <select v-model="selectedPriority" class="typeselect">
                <option value="very_high">Очень высокий</option>
                <option value="high">Высокий</option>
                <option value="average">Средний</option>
                <option value="low">Низкий</option>
                <option value="very_low">Очень низкий</option>
              </select>

            </div>
            <div class="input_wrapper">
              <p>Дедлайн</p>
              <input type="datetime-local" v-model="formattedDeadline" placeholder="Выберите дату выполнения"/>
            </div>
            <textarea style="min-height: 100px; width:100%" v-model="taskText" placeholder="Описание задачи"></textarea>
            <button class="btn" type="submit">Cохранить</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Header } from '@/api/index'
import axios from 'axios'
import { getOrganizationMember } from '@/api/get'

export default {
  data () {
    return {
      userID: this.$cookies.get('ID'),
      taskTitle: '',
      selectedUser: '',
      selectedUserID: null,
      filteredUsers: [],
      task: {},
      selectedTypeID: '',
      typeList: [],
      selectedPriority: '',
      formattedDeadline: '',
      taskText: ''
    }
  },
  props: {
    show: { type: Boolean, default: false },
    data: {
      type: Object,
      required: true
    }
  },
  async mounted () {
    const { data } = await getOrganizationMember(this.userID)
    this.organizationID = data.organization_id
    this.getTypeList()
  },
  watch: {
    show (value) {
      if (value) {
        axios.get(`${process.env.VUE_APP_SERVER_URL}tasks/${this.data.id}`, {
          headers: Header.auth
        }).then(response => {
          const task = response.data
          this.taskTitle = task.title
          this.selectedUser = `${task.executor_name} ${task.executor_surname} ${task.executor_patronymic}`
          this.selectedUserID = task.executor_id
          this.selectedTypeID = task.type_id
          this.selectedPriority = task.priority
          this.formattedDeadline = task.deadline ? task.deadline.slice(0, 16) : ''
          this.taskText = task.text
        })
      }
    }
  },
  methods: {
    hideWindow () {
      this.$emit('update:show', false)
    },
    getTypeList () {
      axios.get(`${process.env.VUE_APP_SERVER_URL}task_types/`, {
        params: { organization_id: this.organizationID },
        headers: Header.auth
      })
        .then(res => {
          this.typeList = res.data
        })
        .catch(err => console.log(err))
    },
    getUsers () {
      axios.get(`${process.env.VUE_APP_SERVER_URL}users/`, {
        headers: Header.auth
      }).then(response => {
        this.filteredUsers = response.data
      })
    },
    searchUser () {
      if (!this.selectedUser) {
        this.filteredUsers = []
        return
      }
      const searchString = this.selectedUser.toLowerCase()
      this.filteredUsers = this.filteredUsers.filter(user => {
        const fullName = `${user.name} ${user.surname} ${user.patronymic}`.toLowerCase()
        return fullName.includes(searchString)
      })
    },
    selectUser (user) {
      this.selectedUser = `${user.name} ${user.surname} ${user.patronymic}`
      this.selectedUserID = user.id
      this.filteredUsers = []
    },
    updateDeadline (event) {
      const { value } = event.target
      this.formattedDeadline = value.slice(0, 16)
    },
    submit () {
      const data = {
        executor_id: this.selectedUserID,
        type_id: this.selectedTypeID,
        title: this.taskTitle,
        deadline: this.formattedDeadline ? `${this.formattedDeadline}` : null,
        text: this.taskText,
        priority: this.selectedPriority
      }
      axios.patch(`${process.env.VUE_APP_SERVER_URL}tasks/${this.data.id}`, data, {
        headers: Header.auth
      })
        .then(response => {
          this.$emit('update:show', false)
          this.$emit('taskEdited')
        })
        .catch(error => console.log(error))
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap');
  .user-selector .body {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  .create_task{
    width:500px;
    display:flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;

  }
  .create_task_logo{
    display:flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .create_task_logo p {
    position: relative;
    top:12px;
    left:5px;
    width: 250px;
    height: 20px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
  }

 .container {
    background: #3E3E42;
    border-radius: 25px;
    padding: 20px;
    position: relative;
    width: 514px;
    height: 800px;
    overflow-y: hidden;
    overflow-x: hidden;

  }

 .input-container {
    position: relative;
  }
  .input_wrapper {
    display:flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top:15px
  }
  .input_wrapper p {
    position: relative;
    top:10px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
  }
  .input_wrapper_2{
    display:flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .input_wrapper_3{
    display:flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .add_minitask{
    margin-top:14px;
    margin-right:23px;
  }
  .delete_minitask{
    position: relative;
    top:-40px;
    left:185px;

  }
  .typeselect{
    width: 267px;
    height: 40px;
    margin-right: 25px;
    background: #6A6A70;
    border: 1px solid #82838A;
    border-radius: 5px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    color:white;
  }

 .container input {
    position: relative;
    left:-25px;
    width: 267px;
    background: #6A6A70;
    border: 1px solid #82838A;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    padding-right: 45px;
    color:white;
  }
  ::placeholder {
  color: white;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
}

  .dropdown {
    position: relative;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .user-selector {
    font-size: 14px;
    margin-left: 5px;
  }

  .user-selector  {
    padding: 10px;
    cursor: pointer;
  }

  .user-selector .dropdown-menu li:hover {
    background-color: #a08585;
  }

  .user-selector .user-list {
    list-style: none;
  width: auto;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #ccc;
  }

  .user-selector .user-list li {
    padding: 10px;
    cursor: pointer;
  }

  .user-selector .user-list li:hover {
    background-color: #b4b0b0;
  }

  .user-selector .mini-tasks input {
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .user-selector .mini-tasks button {
    margin-left: 10px;
    cursor: pointer;
    background-color: #efefef;
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: black;
  }

  .user-selector .mini-tasks button:hover {
    background-color: #dcdcdc;
  }

  .user-selector .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin-bottom: 15px;
  }

  .user-selector .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #405cd5;
    cursor: pointer;
  }

  .user-selector .slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #405cd5;
    cursor: pointer;
  }
  .type-list {
  list-style: none;
  width: 400px;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #ccc;
}

.type-list li {
  padding: 5px 10px;
  cursor: pointer;
}

.type-list li:hover {
  background-color: #ccc;
}
</style>
