<template>
  <div class="body">
     <div class="container">
         <div class="header_filters">
             <input type="text" placeholder="Поиск..." class="search" v-model="searchQuery">
             <div class="search-filters">
              <div>
               <label>Поиск по:</label>
                 <input type="checkbox" id="name" value="name" v-model="filters"><label for="name">Имя</label>
                 <input type="checkbox" id="surname" value="surname" v-model="filters"><label for="surname">Фамилия</label>
                 <input type="checkbox" id="patronymic" value="patronymic" v-model="filters"><label for="patronymic">Отчество</label>
               </div>
               <label>Отдел:</label>
               <select v-model="selectedDep">
                 <option value="">Все</option>
                 <option v-for="dep in departments" :value="dep.id" :key="dep.id">{{dep.title}}</option>
               </select>
               <div class="date_filter">
                 <label for="start_date">Начальная дата:</label>
                 <input type="date" id="start_date" v-model="startDate">
                 <label for="end_date">Конечная дата:</label>
                 <input type="date" id="end_date" v-model="endDate">
               </div>
             </div>
         </div>
         <div class="worktime">
             <div class="titles">
               <p>ФИО</p>
               <p>Кол-во часов</p>
               <p>Статус</p>
               <p>Дата</p>
               <p>Действия</p>
             </div>
             <transition-group name="report">
                 <div class="work_time_overflow">
                     <div v-for="time in filteredWorktime" :key="time.id" class="report" >
                         <div class="report_text">
                             <p>{{ time.user_name }} {{ time.user_surname }} {{ time.user_patronymic }}</p>
                             <p>{{ (() => {
                                     const start = new Date(time.started_at);
                                     const finish = new Date(time.finished_at);
                                     const hours = Math.round((finish - start) / (1000 * 60 * 60));
                                     let hoursWord = 'часов';
                                     const mod10 = hours % 10;
                                     const mod100 = hours % 100;
                                     if (mod10 == 1 && mod100 != 11) {
                                       hoursWord = 'час';
                                     } else if ([2, 3, 4].includes(mod10) && ![12, 13, 14].includes(mod100)) {
                                       hoursWord = 'часа';
                                     }
                                     return `${hours} ${hoursWord}`;
                                })() }}</p>
                             <p><span :class="getClass(time.status)">{{ getStatus(time.status) }}</span></p>
                             <p>{{ new Date(time.created_at).toLocaleDateString('ru-RU') }}</p>
                             <p><button v-if="isToday(time.created_at)" @click="showEditWindow(time.id)">Редактировать</button></p>
                         </div>
                     </div>
                 </div>
             </transition-group>
         </div>
     </div>
     <EditWorkTimeComp v-model:show="show_edit_window" :id="idToEdit"></EditWorkTimeComp>
  </div>
</template>

<script>
/* eslint-disable */
 import EditWorkTimeComp from '@/components/LkMasterPage/EditWorkTimeComp.vue'
 import axios from 'axios'
 import { Header } from '@/api/index'
 export default {
   data () {
     return {
       worktime: [],
       searchQuery: '',
       filters: ['name', 'surname', 'patronymic'],
       startDate: '',
       endDate: '',
       departments: [],
       selectedDep: '',
       show_edit_window: false,
       idToEdit: null
     }
   },
   components:{
      EditWorkTimeComp
   },
   async mounted () {
     await this.fetchWorktime()
     await this.fetchDepartments()
   },
   computed: {
     filteredWorktime () {
       let filtered = this.worktime
       const searchWords = this.searchQuery.trim().toLowerCase().split(' ')
       if (searchWords.length > 0 && this.filters.length > 0) {
         filtered = filtered.filter(time => {
           for (let i = 0; i < this.filters.length; i++) {
             const word = searchWords.find(w => w.length > 0)
             if (word) {
               const field = time[`user_${this.filters[i]}`].trim().toLowerCase()
               if (!field.includes(word)) {
                 return false
               }
             }
           }
           return true
         })
       }
       if (this.selectedDep) {
         filtered = filtered.filter(time => time.departament_id === this.selectedDep)
       }
       if (this.startDate && this.endDate) {
         filtered = filtered.filter(time => {
           const reportDate = new Date(time.created_at).getTime()
           const startDate = new Date(this.startDate).getTime()
           const endDate = new Date(this.endDate).getTime()
           return reportDate >= startDate && reportDate <= endDate
         })
       }
       return filtered
     }
   },
   methods: {
     async fetchWorktime () {
       try {
         const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}work_time/`, {
           headers: Header.auth
         })
         this.worktime = response.data
       } catch (error) {
       }
     },
     async fetchDepartments () {
       try {
         const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}departaments/`, {
           headers: Header.auth
         })
         this.departments = response.data
       } catch (error) {
       }
     },
     getStatus (status) {
        switch (status) {
         case 'worked': return 'Отработал'
         case 'sicked': return 'Болел'
         case 'begged': return 'Отпросился'
         case 'skipped': return 'Отсутствовал'
         default: return status
     }
   },
     getClass (status) {
        switch (status) {
          case 'new': return 'new'
          case 'sicked': return 'sicked'
          case 'begged': return 'begged'
          case 'skipped': return 'skipped'
          default: return ''
    }
  },
  showEditWindow (id) {
      this.idToEdit = id
      this.show_edit_window = true
  },
  isToday (date) {
      const today = new Date().toLocaleDateString('ru-RU')
      const createdDate = new Date(date).toLocaleDateString('ru-RU')
      return today === createdDate
  }
  }
}
</script>

<style scoped>
.body {
 display:flex;
 justify-content: center;
 align-items: center;
 overflow: hidden;
 position: relative;
 width: 100%;
 left: 100px;
}
.container {
 position: relative;
 display:flex;
 align-items: center;
 flex-wrap:nowrap;
 flex-direction: column;
 top:15px;
 right:50px;
 width: 1734px;
 height: 830px;
 background: #5B5B60;
 box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 border-radius: 25px 25px 0px 0px;
}
.header_filters{
  position: relative;
  display:flex;
  flex-direction: row;
  width:100%;
  top:50px;
  left:70px;
}
.search {
  position: relative;
  width: 246px;
  height: 38px;
  left:5px;
  top:-30px;
  background: #D9D9D9;
  border:none;
  border-radius: 5px;
}
.search-filters{
    position: relative;
    margin-top:-15px;
    top:-20px;
    left:100px;
    font-family: mulish;
    color:white;
}
.worktime{
  display:flex;
  flex-wrap:nowrap;
  flex-direction: column;
  position: relative;
  top:-20px;
  display:flex;
  width:95%;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ECC22C;
}
.work_time_overflow{
    width:100%;
    height: 680px;
    overflow-y:scroll;
}
.titles{
    margin-left:180px;
    width:100%;
    display:flex;
    gap: 17%;
}
.titles p{
 font-family: 'Mulish';
 font-style: normal;
 font-weight: 600;
 font-size: 17.9075px;
 color: #FFFFFF;
}
.report{
  display:flex;
  justify-content: left;
  align-items: center;
  width: 1582px;
  height: 58px;
  background: #6A6A70;
  border-radius: 1.41768px;
  margin-top:30px;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}
.report-enter-active, .report-leave-active {
 transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out;
}
.report-enter, .report-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.report:hover {
  transform: translateY(-5px);
}
.report_text{
    width:100%;
    display:flex;
    gap:14%;
}
.report_text p{
 font-family: 'Mulish';
 font-style: normal;
 font-weight: 600;
 font-size: 17.9075px;
 line-height: 22px;
 color: #D9D9D9;
 width: 220px;
 text-align: center;
 padding-top: 10px;
 white-space: nowrap;
 text-overflow: ellipsis;

}
.worked {
  padding: 1px 1px;
  height: 12px;
  background: rgba(190, 69, 69, 0.2);
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: #D9D9D9;
}
.begged {
  padding: 1px 1px;
  height: 12px;
  background: rgba(189, 139, 81, 0.5);
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: #D9D9D9;
}
.skipped {
  padding: 1px 1px;
  height: 12px;
  background: rgba(63, 93, 128, 0.5);
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: #D9D9D9;
}
.sicked{
  padding: 1px 1px;
  height: 12px;
  background: rgba(63, 128, 73, 0.5);
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: #D9D9D9;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

</style>
