<template>
  <div class="reports_body">
    <CreateReportComp v-model:show="show_create_report"></CreateReportComp>
    <div class="container">
      <div class="details" v-if="selectedReportId">
      <ReportDetailsComp :reportId="selectedReportId"></ReportDetailsComp>
      </div>
     <div v-else class="container_visibility">
        <div class="header_filters">
            <input type="text" placeholder="Поиск..." class="search"  v-model="searchQuery">
            <buttom class="create_report" @click="createReport">Составить отчет</buttom>
          </div>
          <div class="reports">
            <div class="titles">
              <p>Заголовок</p>
              <p>Автор</p>
              <p>Статус</p>
              <p>Дата создания</p>
            </div>
            <transition-group name="report">
            <div v-for="report in filteredReports" :key="report.id" class="report"
              @click="openReportDetails(report.id)">
              <div class="report_text">
                <p>{{ report.title }}</p>
                <p>{{ report.creator_name }} {{ report.creator_surname }} {{ report.creator_patronymic }}</p>
                <p>
                  <span :class="getClass(report.status)">{{ getStatus(report.status) }}</span>
                </p>
                <p>{{ new Date(report.created_at).toLocaleDateString('ru-RU') }}</p>
              </div>
            </div>
          </transition-group>
          </div>
        </div>
        </div>
  </div>
</template>

<script>
import { Header } from '@/api/index'
import axios from 'axios'
import CreateReportComp from '@/components/LkMasterPage/CreateReportComp.vue'
import ReportDetailsComp from './ReportDetailsComp.vue'
export default {
  data () {
    return {
      reports: [],
      show_create_report: false,
      selectedReportId: null,
      searchQuery: ''
    }
  },
  components: {
    CreateReportComp,
    ReportDetailsComp
  },
  async mounted () {
    await this.fetchReports()
  },
  methods: {
    createReport () {
      this.show_create_report = true
    },
    async fetchReports () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}reports/`, {
          headers: Header.auth
        })
        this.reports = response.data
      } catch (error) {
      }
    },
    openReportDetails (id) {
      this.selectedReportId = id
      this.$router.push({ path: '/LkMaster/Reports', query: { id: id } })
    },
    closeReportDetails () {
      this.selectedReportId = null
    },
    getStatus (status) {
      switch (status) {
        case 'new':
          return 'Новый'
        case 'completed':
          return 'На проверке'
        case 'accepted':
          return 'Проверенный'
        default:
          return status
      }
    },
    getClass (status) {
      switch (status) {
        case 'new':
          return 'new'
        case 'completed':
          return 'completed'
        case 'accepted':
          return 'accepted'
        default:
          return ''
      }
    }
  },
  computed: {
    filteredReports () {
      return this.reports.filter(report => {
        return report.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      })
    }
  }
}
</script>

<style scoped>
.reports_body {
    display:flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    left: 100px;
}
.container {
    position: relative;
    display:flex;
    flex-wrap:nowrap;
    flex-direction: column;
    top:15px;
    right:50px;
    width: 1734px;
    height: 929px;
    background: #5B5B60;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px 25px 0px 0px;
  }
  .container_visibility{
    width:100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
}
  .header_filters{
    position: relative;
    display:flex;
    width:100%;
    top:50px;
    left:70px;
  }
  .search {
  position: relative;
  width: 246px;
  height: 38px;
  left:5px;
  top:-30px;
  background: #D9D9D9;
  border:none;
  border-radius: 5px;
}
.create_report {
position: relative;
display: flex;
align-items: center;
justify-content: center;
font-family: sans-serif;
top:-30px;
left:10px;
width: 196px;
height: 38px;
background: #ECC22C;
border-radius: 5px;
text-align: center;
cursor: pointer;
}
.reports{
  display:flex;
  flex-wrap:nowrap;
  flex-direction: column;
  position: relative;
  top:40px;
  display:flex;
  width:95%;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ECC22C;

}
.titles{
    margin-left:180px;
    width:100%;
    display:flex;
    gap: 23%;
}
.titles p{
 font-family: 'Mulish';
 font-style: normal;
 font-weight: 600;
 font-size: 17.9075px;
 color: #FFFFFF;
}
.report{
  display:flex;
  justify-content: left;
  align-items: center;
  width: 1582px;
  height: 58px;
  background: #6A6A70;
  border-radius: 1.41768px;
  margin-top:30px;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}
.report-enter-active, .report-leave-active {
 transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out;
}
.report-enter, .report-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.report:hover {
  transform: translateY(-5px);
}
.report_text{
    width:100%;
    display:flex;
    gap:14%;
}
.report_text p{
 font-family: 'Mulish';
 font-style: normal;
 font-weight: 600;
 font-size: 17.9075px;
 line-height: 22px;
 color: #D9D9D9;
 width: 220px;
 text-align: center;
 padding-top: 10px;
 white-space: nowrap;
 text-overflow: ellipsis;

}
.new{
  width: 100px;
  font-size:13px;
  padding:5px;
  padding-left:15px;
  padding-right:15px;
  height: 28px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.completed{
  width: 100px;
  font-size:13px;
  padding:5px;
  padding-left:15px;
  padding-right:15px;
  height: 28px;
  background: rgba(63, 93, 128, 0.5);
  border-radius: 5px;
}
.accepted{
  width: 100px;
  font-size:13px;
  padding:5px;
  padding-left:15px;
  padding-right:15px;
  height: 28px;
  background: rgba(63, 128, 73, 0.69);
  border-radius: 5px;
}
</style>
