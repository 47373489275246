<template>
<div class="list">
  <div class="field">
    <input id="search" class="search" type="text" placeholder="Поиск..." v-model="title">
    <select id="select" class="select" @click="get_all_departaments" v-model="select">
      <option value="false">Обычные отделы</option>
      <option value="true">Заблокированные</option>
      <option value="all">Все отделы</option>
    </select>
    <button id="add" type="button" class="btnAdd" @click="show_add_window(true)">Cоздать отдел</button>
  </div>
  <div class="list__title">
    <div class="list__titleItem">Название</div>
    <div class="list__titleItem">Описание</div>
    <div class="list__titleItem">Действия</div>
  </div>
  <div class="scroll">
    <a class="row" v-for="item in departaments.filter((departament) => departament.title.toLowerCase().includes(this.title.trim().toLowerCase()))" :key="item.id">
      <div class="usersInfo">
        <div class="usersInfo_line">
          <div class="col">{{ item.title }}</div>
          <div class="col">{{ item.text }} </div>
          <div class="btns">
            <button v-if="!item.is_blocked" id="bord_red" class="btn" @click="show_state_window(true, item)"></button>
            <button v-if="!item.is_blocked" id="bord_green" class="btn" @click="submit_departament('change', item)"></button>
            <button v-else id="bord_lite" class="btn" @click="show_state_window(true, item)"></button>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>

<!-- Окно создания отдела -->
<div v-if="add_window" class="add add_container">
  <div class="add_body">
    <div class="add_close">
      <img id="close_1" src="@/assets/images/close.png" alt="close" @click="show_add_window(false)"/>
    </div>
    <p>Создание отдела</p>
    <div id="form" class="add_form">
      <div>
        <label>Руководитель отдела:</label>
        <select class="add_select" id="supervizor" v-model="departament.director_id">
          <option value="" disabled selected>Выберите из списка</option>
          <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }} - {{ user.surname }} - {{ user.patronymic }}</option>
        </select>
      </div>
      <div>
        <label>Помощник отдела:</label>
        <select class="add_select" id="assistant" v-model="departament.assistant_id">
          <option value="" disabled selected>Выберите из списка</option>
          <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }} - {{ user.surname }} - {{ user.patronymic }}</option>
        </select>
      </div>
      <div>
        <label>Название отдела:</label>
        <input-comp id="title" :value="departament.title" :cl="'input_comp'" :message="error.title" @input="departament.title = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Информация об отделе:</label>
        <input-comp id="text" :value="departament.text" :cl="'input_comp'" :message="error.text" @input="departament.text = $event.target.value"></input-comp>
      </div>
    </div>
    <button id="add" class="add_btn" @click="submit_departament('add', departament)">Создать</button>
  </div>
</div>

<!-- Окно удаления / восстановления сотрудника -->
<div v-if="state_window" class="state state_container">
  <div class="state_body">
    <div class="state_close">
      <img id="close_1" src="@/assets/images/close.png" @click="show_state_window(false)">
    </div>
    <p v-if="!temp_departament.is_blocked">Удалить сотрудника</p>
    <p v-else>Восстановить сотрудника</p>
    <div class="state_btns">
      <button v-if="!temp_departament.is_blocked" id="delete" @click="change_departament(temp_departament)">Удалить</button>
      <button v-else id="recover" @click="change_departament(temp_departament)">Восстановить</button>
      <button id="close_2" @click="show_state_window(false)">Закрыть</button>
    </div>
  </div>
</div>
</template>

<script>
import { validTitle, validText } from '@/validation'
import { getAllDepartaments, getAllUsers } from '@/api/get'
import { postAddDepartament } from '@/api/post'
import { patchBlockDepartament, patchRecoverDepartament } from '@/api/patch'

export default {
  data () {
    return {
      add_window: false,
      state_window: false,
      title: '',
      select: 'false',
      departaments: [],
      users: [],
      temp_departament: {},
      departament: {
        director_id: '',
        assistant_id: '',
        title: '',
        text: ''
      },
      error: { title: '', text: '' }
    }
  },
  methods: {
    show_add_window (value) {
      for (const key in this.error) this.error[key] = ''
      this.add_window = value
      if (value) {
        this.get_all_users()
      }
    },
    show_state_window (value, departament = {}) {
      this.state_window = value
      this.temp_departament = departament
    },
    get_all_departaments () {
      getAllDepartaments(this.select).then((response) => {
        this.departaments = response
      })
    },
    get_all_users () {
      getAllUsers('false').then((response) => {
        this.users = response
      })
    },
    submit_departament (value, departament) {
      const err = this.error
      err.title = validTitle(departament.title)
      err.text = validText(departament.text)
      if (!err.title && !err.text) {
        if (value === 'add') {
          postAddDepartament(departament).then((value) => {
            if (value) {
              this.get_all_departaments()
              for (const key in this.departament) this.departament[key] = ''
              this.show_add_window(false)
            }
          })
        }
        if (value === 'change') alert('Пока еще не реализовано на бэке...')
      }
    },
    change_departament (departament) {
      if (departament.is_blocked) {
        patchRecoverDepartament(departament.id).then((response) => {
          if (response) {
            this.get_all_departaments()
            this.show_state_window(false)
          }
        })
      } else {
        patchBlockDepartament(departament.id).then((response) => {
          if (response) {
            this.get_all_departaments()
            this.show_state_window(false)
          }
        })
      }
    }
  },
  mounted () {
    this.get_all_departaments()
  }
}
</script>
