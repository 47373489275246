<template>
  <div class="details_container">
    <CalendarComp v-model:show="calendar_show"></CalendarComp>
    <div class="details_header">
      <p>{{ departament.title }}</p>
    </div>
    <div class="work_area">
      <div class="content">
        <div class="buttons">
        <!-- <button class="monitor" @click="showCalendar">Провести учет</button>
        <button class="monitor" @click="showWorkTime">Сводка рабочего времени</button> -->
      </div>
      <div class="user_list">
        <div class="user_list_header">
          <input
            class="search"
            type="text"
            placeholder="Поиск сотрудника"
            v-model="search">
            <button class="add_employee" @click="addEmployer"></button>
        </div>
        <div class="users">
          <AddEmployeesCompVue v-model:show="show_window_add" :departamentId="departament.id" @employeeAdded="fetchEmlpoyees"></AddEmployeesCompVue>
          <transition-group name="user_palete">
          <div class="user_palete" v-for="employee in filteredEmployees" :key="employee">
            <img :src="getImgUrl(employee.user_photo_url)" width="40" height="40" alt="photo"/>
            <p>{{ employee.user_name }} {{employee.user_surname }} {{ employee.user_patronymic }}</p>
            <button @click="openTimeModal(employee.user_id)">Провести учет времени</button>
          </div>
        </transition-group>
        </div>
        <div v-if="timeModal" class="modale" :class="{active: timeModal}">
      <div class="modal_content">
        <h2>Учет времени</h2>
        <form action="" @submit.prevent="submitTime">
          <div class="form-control">
            <label for="start_time">Дата начала</label>
            <input type="datetime-local" id="start_time" v-model="startTime"/>
          </div>
          <div class="form-control">
            <label for="finish_time">Дата окончания</label>
            <input type="datetime-local" id="finish_time" v-model="finishTime"/>
          </div>
          <div class="form-control checkbox">
            <input type="checkbox" id="sicked" v-model="sicked" @change="updateCheckboxValues('sicked')"/>
            <label for="sick">Больничный</label>
          </div>
          <div class="form-control checkbox">
            <input type="checkbox" id="begged" v-model="begged" @change="updateCheckboxValues('begged')"/>
            <label for="begged">Отпросился</label>
          </div>
          <div class="form-control checkbox">
            <input type="checkbox" id="skipped" v-model="skipped" @change="updateCheckboxValues('skipped')"/>
            <label for="skipped">Пропустил</label>
          </div>
          <div class="form-actions">
            <button class="btn cancel" @click="timeModal = false">Отмена</button>
            <button class="btn primary">Сохранить</button>
          </div>
        </form>
      </div>
    </div>
      </div>
      </div>
      <div class="people">
        <p>Руководители</p>
      <div class="people_wrapper">
       <div class="card">
        <h1>Директор отдела: {{ departament.director_name }} {{ departament.director_surname }} {{ departament.director_patronymic }}</h1>
        <img :src="getImgUrl(departament.director_photo_url)" width="70" height="70" alt="photo"/>
       </div>
       <div class="card">
        <h1>Ассистент директора: {{ departament.assistant_name }} {{ departament.assistant_surname }} {{ departament.assistant_patronymic }}</h1>
        <img :src="getImgUrl(departament.assistant_photo_url)" width="70" height="70" alt="photo"/>
       </div>
       </div>
       </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { Header } from '@/api/index'
import axios from 'axios'
import AddEmployeesCompVue from '@/components/LkMasterPage/AddEmployeesComp.vue'
import CalendarComp from '@/components/LkMasterPage/CalendarComp.vue'
export default {
  data () {
    return {
      departament: [],
      users: [],
      show_window_add: false,
      calendar_show: false,
      id: this.departamentId,
      employees: [],
      search: '',
      timeModal: false,
      startTime: null,
      finishTime: null,
      sicked: false,
      begged: false,
      skipped: false,
      selectedEmployee: null
    }
  },
  computed: {
    filteredEmployees () {
      return this.employees.filter(employee => {
        const regexp = new RegExp(this.search, 'i')
        return (
          regexp.test(employee.user_name) ||
        regexp.test(employee.user_surname) ||
        regexp.test(employee.user_patronymic)
        )
      })
    },
    checkboxValues () {
    return {
      sicked: this.sicked,
      begged: this.begged,
      skipped: this.skipped
    }
  }
  },
  components: {
    AddEmployeesCompVue,
    CalendarComp
  },
  props: {
    departamentId: {
      type: String,
      required: true
    }
  },
  async mounted () {
    await this.fetchDepartament()
    await this.fetchEmployees()
  },
  methods: {
    async fetchDepartament () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}departaments/${this.departamentId}`, {
          headers: Header.auth
        })
        this.departament = response.data
      } catch (error) {
      }
    },
    async fetchEmployees () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}employees/?departament_id=${this.id}`, {
          headers: Header.auth
        })
        this.employees = response.data
      } catch (error) {
      }
    },
    addEmployer () {
      this.show_window_add = true
    },
    getImgUrl (url) {
      return `${process.env.VUE_APP_SERVER_URL}${url}`
    },
    showCalendar () {
      this.calendar_show = true
    },
    openTimeModal (id) {
      this.timeModal = true
      // Сохраняем id выбранного сотрудника
      this.selectedEmployee = id
    },
    async submitTime () {
      // Передаем user_id в post запросе
      try {
        const response = await axios.post(`${process.env.VUE_APP_SERVER_URL}work_time/`, {
          user_id: this.selectedEmployee
        }, {
          headers: Header.auth
        })

        const work_time_id = response.data.id // получаем id созданного объекта work_time

        // Проводим учет времени через API в patch запросе, передавая work_time_id и остальные данные
        await axios.patch(`${process.env.VUE_APP_SERVER_URL}work_time/${work_time_id}`, {
          started_at: this.startTime,
          finished_at: this.finishTime,
          status: this.sicked ? 'sicked' : this.begged ? 'begged' : this.skipped ? 'skipped' : 'worked'
        }, {
          headers: Header.auth
        })
      } catch (error) {
        console.log(error)
      }

      // Закрываем модальное окно
      this.timeModal = false
      // Сбрасываем состояние формы
      this.selectedEmployee = null
      this.startTime = null
      this.finishTime = null
      this.sicked = false
      this.begged = false
      this.skipped = false
    },
    updateCheckboxValues (name) {
    Object.keys(this.checkboxValues).forEach(key => {
      if (key !== name) {
        this[key] = false
      }
    })
  }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@600&display=swap');
h1 {
  color:white;
  font-size: 20px;
}
.details_container{
    position: relative;
    display:flex;
    flex-wrap: nowrap;
    flex-direction: column;
    width:100%;
}
.work_area{
  display:flex;
}
.buttons{
  position: relative;
  width:90%;
  display:flex;
  bottom:10px;
}
.monitor{
display: flex;
align-items: center;
justify-content: center;
font-family: sans-serif;
width: 196px;
height: 38px;
border-radius: 5px;
background: #ECC22C;
background-image: url('@/assets/images/LkMaster/Monitor.png');
background-repeat: no-repeat;
background-position: left 5px center;
border:none;
text-align: center;
cursor: pointer;
}
.content{
  position: relative;
  display:flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  top:40px
}
.people{
  display:flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  width:665px;
  position: relative;
  top:76px;
  height: 800px;
  background-color: rgb(90, 87, 87);
  border-top: 5px solid #ECC22C;
  border-radius: 10px 10px 10px 10px;
  overflow-y:scroll;
  overflow-x:hidden;
}
.people p {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
}
.people_wrapper{
  width:90%;
  display:flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:40px;
  border-top: solid 1px #ECC22C
}
.card{
  display:flex;
  justify-content: center;
  width: 639px;
  height: 137px;
  background: #82838A;
  border-top: 2.38767px solid #ECC22C;
  border-radius: 1.41768px;
  margin-top:25px;
}
.card img {
  border-radius: 70px;
  border: 1px solid rgba(255, 255, 255, 0.521);
}
.assistent_card{
  display:flex;
  justify-content: center;
  width:700px;
  height: 200px;
  background-color: rgb(44, 43, 43);
  border-radius: 13px;
  margin-top:20px;
}
.details_header{
    width:100%;
}
.details_header p{
    font-size:30px;
    font-family: sans-serif;
}
.work_area {
    display:flex;
    justify-content: center;
}
.user_list{
    display:flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    margin-right:30px;
    width:898px;
    height: 800px;
    background-color: rgb(90, 87, 87);
    border-top: 5px solid #ECC22C;
    border-radius: 10px 10px 10px 10px;
    overflow-y:scroll;
}
.user_list_header{
    display:flex;
    width:94%;
    margin-top:30px;
    border-bottom: 1px solid #ECC22C;
    text-align: center;
}
.search {
  position: relative;
  bottom: 5px;
  width: 297px;
  height: 38px;
  background: #D9D9D9;
  border-radius: 5px;
  border:none;
}
.add_employee{
    position: relative;
    width: 44px;
    height: 38px;
    left: 5px;
    top:-5px;
    border: none;
    background: #ECC22C;
    background-image: url('@/assets/images/Workspace/CreateTask.png');
    background-position: left 12px center;
    background-repeat: no-repeat;
    border-radius: 5px;
    cursor: pointer;
}
.user_list_header p{
  font-size:20px;
  color:white;
  font-family: sans-serif;
}
.users {
    margin-top:10px;
}
.user_palete{
    display:flex;
    justify-content: left;
    align-items: center;
    width:836px;
    height: 50px;
    margin-top:10px;
    background: #BEBEBE;
    border-top: 3px solid #ECC22C;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    opacity: 1;
}
.user_palete-enter-active, .user_palete-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out;
}
.user_palete-enter, .user_palete-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.user_palete:hover {
  transform: translateY(-5px);
}
.user_palete img {
  position: relative;
  left:20px;
  border-radius: 50px;
}
.user_palete p {
  position: relative;
  left:40px;
  color:white;
  font-family: sans-serif;
}
.add_user{
    background-color:#ECC22C;
    border-radius: 7px;
    border:none;
    width:150px;
    height: 20px;
}

.modale {
  position:fixed;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 100%;
  left:500px;
  color:white;
  font-family: mulish;
}
.modal_content{
  position: relative;
  display:flex;
  align-items: center;
  flex-direction: column;
  border-radius: 25px;
  width:400px;
  height: 500px;
  background-color:rgb(46, 45, 45);
}
.form-control{
  display:flex;
  width:100%;
  justify-content: space-between;
}
.people::-webkit-scrollbar {
  width: 10px;
}

.people::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.people::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5px;
}

.people::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.people::-webkit-scrollbar-thumb:active {
  background-color: #777;
}

.user_list::-webkit-scrollbar {
  width: 10px;
}

.user_list::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.user_list::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5px;
}

.user_list::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.user_list::-webkit-scrollbar-thumb:active {
  background-color: #777;
}

</style>
