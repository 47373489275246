<script>
export default {
  methods: {
    getClass (status, successful) {
    /* eslint-disable */
    if(successful === false) {
      if (this.theme === 'dark') {
          return 'status-decline-dark'
        }
        return 'status-decline-light'
    }
    switch (status) {
      case 'new':
        if (this.theme === 'dark') {
          return 'status-new-dark'
        }
        return 'status-new-light'

      case 'working':
        if (this.theme === 'dark') {
          return 'status-working-dark'
        }
        return 'status-working-light'

      case 'completed':
        if (this.theme === 'dark') {
          return 'status-completed-dark'
        }
        return 'status-completed-light'

      case 'accepted':
        if (this.theme === 'dark') {
          return 'status-accepted-dark'
        }
        return 'status-accepted-light'

      default: return ''
      }
    },
  }
}
</script>
