<template>
  <div class="wrapper">
    <div class="btns">
      <button @click="startWork" v-if="!isWorking" class="btn">Приступить к работе</button>
      <button @click="endWork" v-else class="btn">Закончить работу</button>
    </div>
    <div v-if="isWorking" class="timer"
    v-motion
    :initial="{ opacity:0, x:-300 }"
    :enter=" {opacity:1 , x: 0 ,transition: {
      delay: 1000,
    },}">
    <p :style="{ transform: `scale(${scaleFactor})`}">Вы работаете: {{ getTimeWorked() }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      isWorking: false,
      startTime: null,
      endTime: null,
      scaleFactor: 1
    }
  },
  methods: {
    animate () {
      this.scaleFactor = 0.99
      setTimeout(() => {
        setTimeout(() => {
          this.scaleFactor = 1
        }, 300)
      }, 300)
    },
    startWork () {
      this.isWorking = true
      this.startTime = Date.now()
      localStorage.setItem('startTime', this.startTime)
      axios.post(`${process.env.VUE_APP_SERVER_URL}work_time/`, {
        user_id: localStorage.getItem('ID')
      })
    },
    endWork () {
      this.isWorking = false
      this.endTime = Date.now()
      localStorage.setItem('endTime', this.endTime)
      axios.patch(`${process.env.VUE_APP_SERVER_URL}work_time/`, {
        user_id: localStorage.getItem('ID')
      })
    },
    getTimeWorked () {
      if (!this.isWorking || !this.startTime) {
        return 'Invalid'
      } else {
        const timeWorked = this.endTime
          ? this.endTime - this.startTime
          : Date.now() - this.startTime
        const seconds = Math.floor(timeWorked / 1000) % 60
        const totalMinutes = Math.floor(timeWorked / 1000 / 60)
        const hours = Math.floor(totalMinutes / 60)
        const minutes = totalMinutes % 60
        return `${hours} Часов ${minutes} Минут ${seconds} Секунд`
      }
    },
    updateTimeWorked () {
      this.endTime = Date.now()
      this.getTimeWorked()
      this.animate()
    },
    storeTimerState () {
      if (this.isWorking) {
        localStorage.setItem('isWorking', 'true')
        localStorage.setItem('startTime', this.startTime)
        localStorage.setItem('endTime', this.endTime)
      } else {
        localStorage.setItem('isWorking', 'false')
      }
    }
  },
  created () {
    this.isWorking = localStorage.getItem('isWorking') === 'true'
    this.startTime = parseInt(localStorage.getItem('startTime'))
    this.endTime = parseInt(localStorage.getItem('endTime'))

    setInterval(this.updateTimeWorked, 1000)
    window.addEventListener('beforeunload', this.storeTimerState)
  },
  beforeUnmount () {
    this.storeTimerState()
    window.removeEventListener('beforeunload', this.storeTimerState)
  }
}
</script>

<style scoped>
p {
  color: white;
  font-size: 15px;
  font-family: sans-serif;
  transition: transform 0.3s, opacity 0.3s;
}
.wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}
.btns {
  position: relative;
  top: 20px;
  margin-left: 20px;
}
.btn {
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  font-family: sans-serif;
  background-color: rgb(37, 37, 37);
  border: solid 1px rgba(255, 255, 255, 0.322);
  border-radius: 15px;
  top: 30px;
}
.btn:hover {
  transition: all ease 0.3s;
  background-color: rgb(66, 64, 64);
}
.timer {
  position: relative;
  top: 20px;
}
</style>
