<template>
    <div class="confirmation-window" v-if="show">
      <div class="body">
        <p>Вы действительно хотите удалить задачу?</p>
        <div class="buttons">
          <button @click="deleteTask" class="accept">Да</button>
          <button @click="$emit('update:show', false)" class="cancel">Отмена</button>
        </div>
      </div>
    </div>
  </template>

<script>
import { Header } from '@/api/index'
import axios from 'axios'

export default {
  props: {
    show: { type: Boolean, default: false },
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    deleteTask () {
      this.$emit('update:show', false)
      this.$emit('taskBlocked')
      axios.patch(`${process.env.VUE_APP_SERVER_URL}tasks/${this.data.id}/block`, null, {
        headers: Header.auth
      })
        .then(() => {
        })
        .catch((err) => {
          console.log('Error:', err)
        })
    }
  }
}
</script>

<style scoped>
.confirmation-window{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width:100%;
    height: 100%;
    top:0;
    left:140px;
    z-index:100;
}
.body{
    display:flex;
    flex-wrap:nowrap;
    flex-direction: column;
    width:400px;
    height: 100px;
    background-color:rgb(110, 108, 108);
}

.body p{
  text-align:center;
  margin-top: 30px;
  font-size:20px;
  color:white;
}

.buttons{
  display:flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
}

.accept{
  margin: 0 10px;
  background-color: rgb(40, 165, 97);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
.cancel{
  margin: 0 10px;
  background-color: rgb(165, 55, 40);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.accept:hover{
  background-color: rgb(50, 185, 117);
}
.cancel:hover{
    background-color: rgb(180, 63, 48);
}

</style>
