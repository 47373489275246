import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import VueCookies from 'vue-cookies'
import components from '@/components'
// import { Header } from '@/api/index'
// import axios from 'axios'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueCookies)

components.forEach(component => { app.component(component.name, component) })

app.mount('#app')
