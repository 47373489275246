import axios from 'axios'
import store from '@/store'
import { Header } from '@/api/index'

// User
export const getUserActive = () => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}users/me`, { headers: Header.auth })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение активного пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      store.dispatch('logout')
    })
}
// Appeals
export const getAllAppeals = (param) => {
  getUserActive()

  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}appeals/?is_blocked=${param}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение списка обращений):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен '}.`)
    })
}

// Departament
export const getAllDepartaments = (param) => {
  getUserActive()

  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}departaments/?is_blocked=${param}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение списка отделов):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

// Direction
export const getAllDirections = (param) => {
  getUserActive()

  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}directions/?is_blocked=${param}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение списка направлений):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

// Employee
export const getAllEmployees = (param) => {
  getUserActive()

  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}employees/?is_blocked=${param}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение списка сотрудников):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

// Estime
export const getAllEstimates = (param) => {
  getUserActive()

  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}estimates/?is_blocked=${param}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение списка оценок):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

export const getUserNotifications = () => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}notifications_service/notifications/`, { headers: Header.auth })
    .then((response) => { if (response.data) return response.data })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение уведомлений пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

// Task
export const getUserTasks = (orgId, userId) => {
  const task = {
    organization_id: orgId,
    executor_id: userId
  }

  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}tasks/`, { params: task, headers: Header.auth })
    .then((response) => {
      if (response) return response
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение задач пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

// Role
export const getAllRoles = (param) => {
  getUserActive()

  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}roles/?is_blocked=${param}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение списка ролей):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

export const getAllUsers = (param) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}users/?is_blocked=${param}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение списка пользователей):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

// Event
export const getAllEvents = () => {
  getUserActive()

  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}events/`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение всех событий):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

export const getTaskById = (taskId) => {
  return axios.get(`${process.env.VUE_APP_SERVER_URL}tasks/${taskId}`, { headers: Header.auth })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getAllTasks = (orgId, isBlocked = false) =>
  axios.get(`${process.env.VUE_APP_SERVER_URL}tasks/?organization_id=${orgId}`, { headers: Header.auth })

export const getUserId = (id) => axios.get(`${process.env.VUE_APP_SERVER_URL}users/${id}`)

export const getAppealId = (id) => axios.get(`${process.env.VUE_APP_SERVER_URL}appeals/${id}`)

export const getDirectionId = (id) => axios.get(`${process.env.VUE_APP_SERVER_URL}directions/${id}`, { headers: Header.auth })

export const getCreaterTasks = (orgId, executorId) => {
  axios.post(`${process.env.VUE_APP_SERVER_URL}tasks/get_all_with_or_filter?organization_id=${orgId}&executor_id=${executorId}`, { headers: Header.auth })
    .then((response) => console.log('res', response))
  // return axios
  //   .post(`${process.env.VUE_APP_SERVER_URL}tasks/get_all_with_or_filter/?organization_id=${orgId}&executor_id=${executorId}`, { headers: Header.auth })
  //   .then((response) => console.log('res', response))
  //   .catch((error) => {
  //     console.log(`Ошибка GET запроса (получение задач пользователя):
  //     1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
  //     2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
  //   })
}

export const getSpectatorTasks = (id) => {
  const task = {
    spectator_id: id,
    is_blocked: false
  }

  return axios.get(`${process.env.VUE_APP_SERVER_URL}tasks/`, {
    params: task,
    headers: Header.auth
  })
    .then((response) => response)
    .catch((error) => console.log('ERROR PATCH block user: ', error.toJSON()))
}

// reports

export const getAllReports = (param) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}reports/?is_blocked=${param}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение списка пользователей):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

// chat messages

export const getLastChatMessages = (isBlocked, userId, lastDate) => {
  let url = `${process.env.VUE_APP_SERVER_URL}main_messages/?user_id=${userId}&is_blocked=${isBlocked}`
  if (lastDate) {
    url += `&from_date=${lastDate}`
  }

  getUserActive()

  return axios
    .get(url, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение списка пользователей):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

// получить список чатов

export const getChatsList = (isBlocked, orgId, userId) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}chats/?organization_id=${orgId}&is_blocked=${isBlocked}&user_id=${userId}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение списка пользователей):
      1)ответ: ${error.response ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response ? error.response.status : 'не определен'}.`)
    })
}

export const getChatsMessages = (isBlocked, chatId) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}chat_messages/?is_blocked=${isBlocked}&chat_id=${chatId}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение списка пользователей):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

// получить организации
export const getOrganizations = () => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}organizations/`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение cписка организаций):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      store.dispatch('logout')
    })
}

export const getOrganizationsByInn = (inn) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}organizations/?inn=${inn}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение cписка организаций):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      store.dispatch('logout')
    })
}

export const getOrganizationsUsers = (orgId) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}organization_members/?is_blocked=false&organization_id=${orgId}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение cписка участников организациии):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      store.dispatch('logout')
    })
}

export const getOrganizationsUsersAll = (orgId) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}organization_members/?organization_id=${orgId}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение cписка участников организациии):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      store.dispatch('logout')
    })
}

export const getOrganizationByUserId = async (userId) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}organization_members/get_by_user_id/${userId}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch(() => {
      return false
    })
}

export const getOrganizationByOrganizationId = async (id) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}organizations/${id}`, { headers: Header.auth })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch(() => {
      return false
    })
}

export const getOrganizationMember = (userId) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}organization_members/get_by_user_id/${userId}`, { headers: Header.auth })
    .then((response) => { if (response) return response })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение участника организации):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

export const getUserById = async (userId) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}users/${userId}`, { headers: Header.auth })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение участника организации):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      store.dispatch('logout')
    })
}

export const getAllAccounts = async (orgId) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}accounts/?organization_id=${orgId}`, { headers: Header.auth })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получения транзакции):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      store.dispatch('logout')
    })
}

export const getAccountsBalanceSheet = async (orgId) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}accounts/balance_sheet?organization_id=${orgId}`, { headers: Header.auth })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получения транзакции):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      store.dispatch('logout')
    })
}

export const getTaskComments = async (orgId) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}comments/?task_id=${orgId}`, { headers: Header.auth })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получения комментариев задачи):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      store.dispatch('logout')
    })
}

export const transformVoiceToText = async (data) => {
  console.log('data', data)
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}sound_to_text/convert_audio`, data, { headers: Header.auth })
    .then((response) => {
      return response
    })
    .catch(() => {
      console.log('Ошибка трансформации')
    })
}

// TimesheetWorkers
export const getTimesheetWorkers = async (orgId) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}timesheet_service/work_days/?organization_id=${orgId}`, { headers: Header.auth })
    .then((response) => {
      return response
    })
    .catch(() => {
      console.log('Ошибка получения рабочего времени')
    })
}

export const getTimesheetWorkersDateFilter = async (orgId, dateStart, dateEnd) => {
  const data = {
    start_date: dateStart,
    end_date: dateEnd
  }

  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}timesheet_service/work_days/?organization_id=${orgId}`, { params: data, headers: Header.auth })
    .then((response) => {
      return response
    })
    .catch(() => {
      console.log('Ошибка получения рабочего времени')
    })
}

export const getTimesheetWorkersFilterMonth = async (orgId, date) => {
  let month = null
  let year = null
  const data = {}
  if (date) {
    [year, month] = date.split('-')
    data.month = month
    data.year = year
  }

  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}timesheet_service/work_days/?organization_id=${orgId}`, { params: data, headers: Header.auth })
    .then((response) => {
      return response
    })
    .catch(() => {
      console.log('Ошибка получения рабочего времени')
    })
}

export const getTimesheetWorkersDay = async (orgId, date) => {
  let month = null
  let year = null
  let day = null
  const data = {}
  if (date) {
    [year, month, day] = date.split('-')
    data.specific_date = year + '-' + month + '-' + day
  }

  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}timesheet_service/work_days/?organization_id=${orgId}`, { params: data, headers: Header.auth })
    .then((response) => {
      return response
    })
    .catch(() => {
      console.log('Ошибка получения рабочего времени')
    })
}

export const getTimesheetWorkersNotAuth = async (orgId) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}timesheet_workers/organization/${orgId}`, { headers: Header.auth })
    .then((response) => {
      return response
    })
    .catch(() => {
      console.log('Ошибка получения пользователя')
    })
}

export const getTimesheetWorkerById = async (orgId, data) => {
  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}timesheet_service/work_days/?organization_id=${orgId}`, { params: data, headers: Header.auth })
    .then((response) => {
      return response
    })
    .catch(() => {
      console.log('Ошибка получения рабочего времени')
    })
}
