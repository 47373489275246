<template>
  <div class="report_container">
    <div class="report_header">
      <p>{{ report.title }}</p>
    </div>
    <div class="report_content">
      <div class="description">
        <div class="report_actions">
          <button class="create_report" @click="goBack">Назад</button>
          <p><span :class="getClass(report.status)">{{ getStatus(report.status) }}</span></p>
          <button v-if="report.status === 'new'" class="send" @click="sendReport">Отправить на проверку</button>
        </div>
        <p>Автор: {{ report.creator_name }} {{ report.creator_surname }} {{ report.creator_patronymic }}</p>
        <p>Дата создания: {{ new Date(report.created_at).toLocaleDateString('ru-RU') }}</p>
        <p>Последнее изменение: {{ new Date(report.updated_at).toLocaleDateString('ru-RU') }}</p>
      </div>
      <div class="photos">
        <div class="photo" v-for="(photo) in photos" :key="photo.id" @click="openPhoto(photo.id)">
          <img v-if="photo.photo_url" class="image" :src="getImgUrl(photo.photo_url)"  alt="Ошибка изображение" :width="260">
        </div>
      </div>
      <div class="modal" v-if="selectedPhotoId">
        <PhotoReviewComp :photoId="selectedPhotoId"></PhotoReviewComp>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoReviewComp from './PhotoReviewComp.vue'
import { Header } from '@/api/index'
import axios from 'axios'
export default {
  data () {
    return {
      report: [],
      photos: [],
      id: this.reportId,
      search: '',
      modalShow: false,
      selectedPhotoId: null
    }
  },
  components: {
    PhotoReviewComp
  },
  props: {
    reportId: {
      type: String,
      required: true
    }
  },
  async mounted () {
    await this.fetchReport()
    await this.fetchPhotos()
  },
  methods: {
    async fetchReport () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}reports/${this.id}`, {
          headers: Header.auth
        })
        this.report = response.data
      } catch (error) {
      }
    },
    async fetchPhotos () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}photos/?report_id=${this.id}`, {
          headers: Header.auth
        })
        this.photos = response.data
      } catch (error) {
      }
    },
    sendReport () {
      axios.patch(`${process.env.VUE_APP_SERVER_URL}reports/${this.id}/complete`, null, {
        headers: Header.auth
      })
        .then(() => {
          this.fetchReport()
        })
        .catch(() => {
          console.log('Ошибка при отправке отчета')
        })
    },
    getImgUrl (url) {
      return `${process.env.VUE_APP_SERVER_URL}${url}`
    },
    getStatus (status) {
      switch (status) {
        case 'new':
          return 'Новый'
        case 'completed':
          return 'На проверке'
        case 'accepted':
          return 'Проверенный'
        default:
          return status
      }
    },
    getClass (status) {
      switch (status) {
        case 'new':
          return 'new'
        case 'completed':
          return 'completed'
        case 'accepted':
          return 'accepted'
        default:
          return ''
      }
    },
    openPhoto (id) {
      this.selectedPhotoId = id
      this.$router.push({ path: '/LkMaster/Reports', query: { id: id } })
    },
    closePhoto () {
      this.selectedPhotoId = null
    },
    goBack () {
      this.$router.push({ path: '/LkMaster/Reports', force: true })
      this.$parent.closeReportDetails()
    }
  }
}
</script>

<style scoped>
.report_container{
  display:flex;
  align-items: center;
  padding: 10px;
  flex-wrap: nowrap;
  flex-direction: column;
  width:100%;
  height:100%;
}
.report_header{
  width:98%;
  display:flex;
  justify-content: space-between;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #FFFFFF;
}
.report_content{
  display:flex;
  justify-content: space-between;
  width:100%;
}
.report_actions{
  display:flex;
}
.send{
  margin-top:2px;
  margin-left:6px;
  width: 196px;
  height: 38px;
  background: #ECC22C;
  border-radius: 5px;
  font-family: 'Mulish';
  border:none;
}
.description{
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 40px;
  color: #FFFFFF;
}
.photos{
  display:flex;
  flex-wrap:wrap;
  width:820px;
  height: 750px;
  overflow-y:scroll;
  gap:10px;
}
.new{
  width: 100px;
  font-size:13px;
  padding:11px;
  padding-left:15px;
  padding-right:15px;
  height: 28px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.completed{
  width: 100px;
  font-size:13px;
  padding:11px;
  padding-left:15px;
  padding-right:15px;
  height: 28px;
  background: rgba(63, 93, 128, 0.5);
  border-radius: 5px;
}
.accepted{
  width: 100px;
  font-size:13px;
  padding:11px;
  padding-left:15px;
  padding-right:15px;
  height: 28px;
  background: rgba(63, 128, 73, 0.69);
  border-radius: 5px;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_body {
  background-color: #292929;
  border-radius: 10px;
  width: 1105px;
  height: 856px;
  display: flex;
  flex-direction: column;
}
.modal_body_part_1{
  display:flex;
  flex-direction: row;
  width:100%;
  height: 100%;
}
.modal_body_left{
  display:flex;
  width:100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.modal_body_right{
  display:flex;
  flex-direction: column;
  width:400px;
  height: 100%;
  border-left: 1px solid rgba(130, 131, 138, 0.8);
}
.modal_body_right_header{
  height:66px;
  background-color: #49494E;
  border-radius: 0px 10px 0px 0px;
}
.modal_body_right_comments{
  height: 645px;
}
.modal_body_right_send{
  max-height: 80px;
  height: 100%;
  display:flex;
  align-items: center;
}
.comment{
  width:230px;
  height: 60px;
  background-color: #292929;
  font-family: 'mulish';
  display: block;
  padding-top: 15px;
  margin: auto;
  color:white;
  resize: none;
  border:none;
}
.comment::placeholder {
  text-align: left;
}
.comment:focus{
  outline: none;
}
.user_photo{
  width:50px;
  border-radius: 25px;
}

.modal_description {
  position: relative;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  width:100%;
  height: 70px;
  border-top: 1px solid rgba(130, 131, 138, 0.8);
  text-align: left;
}
.modal_description p {
  margin-left:20px;
}

.modal_close {
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.3s ease;
}

.modal_close:hover {
  background-color: #0062cc;
}

</style>
