<template>
    <div v-if="show" class="modal">
      <div class="icons">
        <div class="icons_left_wrap">
        <a><img src='@/assets/images/LkMaster/CreateReportWhite.svg'></a>
        <p>Новый отчет</p>
      </div>
        <a><img @click="hideWindow" src='@/assets/images/LkMaster/CloseCreateReportWhite.svg'></a>
      </div>
      <div class="container">
        <div class="loading" v-if="loading"></div>
        <div class="error" v-if="error">Произошла ошибка</div>
      <form @submit="submitForm" v-if="(!loading && !error)">
          <div class="title_wrap">
          <label for="title">Заголовок</label>
          <input class="title" type="text" v-model="title" id="title" required>
        </div>
          <div class="title_wrap">
            <label for="text">Описание</label>
          <textarea class="text" type="text" v-model="text" id="text" required />
          </div>
        <div class="dropzone" @dragover.prevent @drop="handleDrop">
          <label for="photos" v-if="previewPhotos.length === 0">Перетащите файлы сюда:</label>
          <input type="file" multiple ref="photos" id="photos" @change="handlePhotosChange">
          <div v-if="previewPhotos.length > 0" class="preview">
            <div v-for="(previewPhoto, index) in previewPhotos" :key="index">
              <img :src="previewPhoto" alt="preview photo">
              <label for="photo_text">Название фото:</label>
              <input type="text" v-model="photoText[index]" name="photo_text">
            </div>
          </div>
        </div>
        <button type="submit">Отправить</button>
      </form>
    </div>
    </div>
  </template>

<script>
/* eslint-disable */
import { Header } from '@/api/index'
import axios from 'axios'

export default {
  data () {
    return {
      title:'',
      text: '',
      photos: [],
      previewPhotos: [],
      photoText: [],
      loading: false,
      error: false
    }
  },
  props: {
    show: { type: Boolean, default: false }
  },
  methods: {
    handlePhotosChange () {
      const files = this.$refs.photos.files
      for (let i = 0; i < files.length; i++) {
        this.photos.push(files[i])
        this.previewPhotos.push(URL.createObjectURL(files[i]))
        this.photoText.push('')
      }
    },
    handleDrop (e) {
      e.preventDefault()
      const files = e.dataTransfer.files
      for (let i = 0; i < files.length; i++) {
        this.photos.push(files[i])
        this.previewPhotos.push(URL.createObjectURL(files[i]))
        this.photoText.push('')
      }
    },
    submitForm () {
      this.loading = true
      const report = {
        title: this.title,
        text: this.text
      }
      axios.post(`${process.env.VUE_APP_SERVER_URL}reports/`, report, {
        headers: Header.auth
      })
        .then(response => {
          for (let i = 0; i < this.photos.length; i++) {
            const formData = new FormData()
            formData.append('image', this.photos[i])
            axios.post(`${process.env.VUE_APP_SERVER_URL}photos/?report_id=${response.data.id}&text=${this.photoText[i]}`, formData, { headers: Header.mult}
              )
              .then(response => {
                setTimeout(() => {
                  this.loading = false
                  this.hideWindow()
                  this.$parent.fetchReports()
               }, 1400)
              })
              .catch(error => {
                console.log(error)
                this.loading = false
                this.error = true
                setTimeout(() => {
                  this.error = false
                }, 1500)
             })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.error = true
          setTimeout(() => {
              this.error = false
          }, 1500)
        })
    },
    hideWindow () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style scoped>
    .modal {
      position: fixed;
      display:flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.678);
      font-family: 'mulish';
      color:white;
      width:100%;
      height: 100%;
      top:0;
      left:0;
      z-index: 50;
    }
    .icons{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      width:23%;
    }
    .icons_left_wrap{
      display:flex;
      flex-direction: row;
      font-size:25px;
    }
    .icons_left_wrap p {
      margin-left:10px;
      margin-top:10px;
    }
    .container{
      width: 514px;
      min-height: 432px;
      background-color: #3E3E42;
      border-radius: 25px;
    }
    .title_wrap {
      width:80%;
      display:flex;
      justify-content: space-between;
      font-family: 'mulish';
      margin-top:15px;
      color:white;
    }
    .title {
      width: 267px;
      height: 32px;
      background: #6A6A70;
      border: 1px solid #82838A;
      color:white;
      border:none;
      border-radius: 5px;
    }
    .title:focus{
      outline: none;
    }
    .text{
      width: 267px;
      height: 100px;
      background: #6A6A70;
      border: 1px solid #82838A;
      color:white;
      resize: none;
      border-radius: 5px;
    }
    .text:focus{
      outline: none;
    }
    form {
      display: flex;
      width:100%;
      flex-direction: column;
      align-items: center;
    }

    label {
      font-weight: bold;
    }

    button[type="submit"] {
      width: 130px;
      height: 41px;
      background: rgba(63, 128, 73, 0.69);
      font-family: 'mulish';
      font-size: 16px;
      margin-top:35px;
      margin-bottom:5px;
      padding: 5px;
      color:white;
      border-radius: 5px;
      border:none;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
    }

    button[type="submit"]:hover {
      background: rgba(76, 153, 88, 0.69);
    }

    .dropzone {
      margin-top: 10px;
      border: 2px dashed #D9D9D9;
      padding: 60px;
      text-align: center;
    }

    .dropzone:hover {
      border-color: #005bbc;
    }

    input[type="file"] {
      display: none;
    }

    .preview {
      margin-top: 10px;
      max-height: 200px;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
}

    .preview img {
      width: 120px;
      object-fit: cover;
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      box-shadow: 0 0 5px #ccc;
    }
  .loading {
  position: relative;
  display:flex;
  width:100%;
  justify-content: center;
  align-items: center;
  top:190px;
}
.loading:before {
  content: '';
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: loading 1.2s linear infinite;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.error {
  position: relative;
  display:flex;
  width:100%;
  justify-content: center;
  align-items: center;
  top:190px;
}
</style>
