<template>
  <div class="list">
    <div class="field">
        <button id="add" type="button" class="btnAdd" @click="openNewsModal">Cоздать новость</button>
    </div>
    <div class="scroll" v-for="event in events" :key="event.id">
        <div class="usersInfo">
            <div class="usersInfo_line">
              <div class="container_news">
                <img :src="`https://delevopers.ru/${event.photo_url}`" alt="news-photo" class="news_image">
                <p>{{ event.title.substring(0, 25) + (event.title.length > 10 ? '...' : '') }}</p>
                <p>{{ event.text.substring(0, 20) + (event.text.length > 10 ? '...' : '') }}</p>
              </div>
            </div>
        </div>
    </div>
</div>

 <div class="add add_container" v-if="createNews">
   <div class="add_body">
     <div class="add_close"><img id="close_1" src="@/assets/images/close.png" alt="close" @click="openNewsModal"/></div>
     <p>Создание новости</p>
     <div id="form" class="add_form">
       <div>
          <form @submit.prevent="submitNews">
              <div style="display: block;">
                <p>Название новости<input v-model="title" type="text" required></p>
                <h4>Описание новости</h4>
                <p><textarea v-model="text" cols="30" rows="10" required></textarea></p>
                <h4>Выберите изображение для новостной ленты</h4>
                <p><input type="file" @change="handleImageChange" accept="image/*" required></p>
            </div>
            <button id="add" class="add_btn" type="submit">Создать</button>
          </form>
       </div>
     </div>
   </div>
</div>
</template>
<script>
import { Header } from '@/api/index'
import axios from 'axios'
import { getAllEvents } from '@/api/get'

export default {
  data () {
    return {
      createNews: false,
      events: [],

      title: '',
      text: '',
      image: null
    }
  },
  methods: {
    openNewsModal () {
      this.createNews = !this.createNews
    },
    handleImageChange (event, image) {
      this.image = event.target.files[0]

      if (image) {
        const reader = new FileReader()

        reader.onload = () => {
          this.image = reader.result
        }

        reader.readAsDataURL(image)
      }
    },
    get_all_events () {
      getAllEvents().then((response) => { this.events = response })
    },
    submitNews () {
      const formData = new FormData()
      formData.append('image', this.image)

      axios.post(`${process.env.VUE_APP_SERVER_URL}events/?title=${this.title}&text=${this.text}`, formData, { headers: Header.mult })
        .then(response => {
          console.log(response)
          this.get_all_events()
        })
        .catch(error => {
          console.error('Ошибка при отправке запроса:', error)
        })
      this.createNews = false
    }
  },
  mounted () {
    this.get_all_events()
  }
}
</script>
<style scoped>
.usersInfo {
    height: 119px;
    width: 100%;
}
.usersInfo_line {
    height: 117px;
    width: 100%;
}
.scroll {
    height: 15vh;
    position: relative;
    overflow-y: auto;
}
.container_news {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.container_news img {
    width: 116px;
    height: auto;
}
</style>
