<template>
  <div class="body">
    <div class="container">
      <div class="details" v-if="selectedDepartamentId">
      <DepartamentDetailsComp :departamentId="selectedDepartamentId"></DepartamentDetailsComp>
      </div>
      <div v-else class="container_visibility">
        <div class="header_filters">
          <input type="text" placeholder="Поиск отдела" class="search" v-model="searchQuery">
        </div>
        <div class="departaments">
          <div class="titles">
            <h2>Наименование</h2>
            <h2>Директор</h2>
            <h2>Дата создания</h2>
          </div>
          <transition-group name="departament">
          <div v-for="departament in filteredDepartaments" :key="departament.id" class="departament"
            @click="openDepartamentDetails(departament.id)">
            <div class="departament_text">
              <p>{{ departament.title }}</p>
              <p>{{ departament.director_name }} {{ departament.director_surname }} {{ departament.director_patronymic }}</p>
              <p>{{ new Date(departament.created_at).toLocaleDateString('ru-RU') }}</p>
            </div>
          </div>
        </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Header } from '@/api/index'
import axios from 'axios'
import DepartamentDetailsComp from './DepartamentDetailsComp.vue'

export default {
  data () {
    return {
      departaments: [],
      selectedDepartamentId: null,
      searchQuery: ''
    }
  },
  components: {
    DepartamentDetailsComp
  },
  async mounted () {
    await this.fetchDepartaments()
  },
  methods: {
    async fetchDepartaments () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}departaments/`, {
          headers: Header.auth
        })
        this.departaments = response.data
      } catch (error) {
      }
    },
    openDepartamentDetails (id) {
      this.selectedDepartamentId = id
      this.$router.push({ path: '/LkMaster/Departaments', query: { id: id } })
    }
  },
  computed: {
    filteredDepartaments () {
      return this.departaments.filter(departament => {
        return departament.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      })
    }
  }
}
</script>

<style scoped>
.body {
  display:flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  left: 100px;
}
.container {
  position: relative;
  display:flex;
  flex-wrap:nowrap;
  flex-direction: column;
  top:15px;
  right:50px;
  width: 1734px;
  height: 929px;
  background: #5B5B60;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px 25px 0px 0px;
}
.details{
  width:100%;
}
.container_visibility{
  width:100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
}
.header_filters{
  position: relative;
  display:flex;
  width:100%;
  top:50px;
  left:70px;
}
.search {
  position: relative;
  width: 246px;
  height: 38px;
  left:5px;
  top:-30px;
  background: #D9D9D9;
  border:none;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.search:focus {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  outline: none;
  top: -32px;
}
.departaments{
  display:flex;
  flex-wrap:nowrap;
  flex-direction: column;
  position: relative;
  top:40px;
  display:flex;
  width:95%;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ECC22C;
}
.titles{
  margin-left:70px;
  width:100%;
  display:flex;
  gap: 20%;
}
.departament{
  display:flex;
  justify-content: left;
  align-items: center;
  width: 1582px;
  height: 58px;
  background: #6A6A70;
  border-radius: 5px;
  margin-top:30px;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}
.departament-enter-active, .departament-leave-active {
 transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out;
}
.departament-enter, .departament-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.departament:hover {
  transform: translateY(-5px);
}
.departament_text{
  margin-left:10px;
  width:100%;
  display:flex;
  gap:14%;
}
.departament_text p {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 17.9075px;
  line-height: 22px;
  color: #D9D9D9;
  width: 220px;
  text-align: center;
  padding-top: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
