import VueCookies from 'vue-cookies'

export const cookies = VueCookies

export const Header = {
  login: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  auth: {
    Authorization: `Bearer ${cookies.get('JWT')}`
  },
  json: {
    Authorization: `Bearer ${cookies.get('JWT')}`,
    'Content-Type': 'application/json'
  },
  mult: {
    Authorization: `Bearer ${cookies.get('JWT')}`,
    'Content-Type': 'multipart/form-data'
  }
}
