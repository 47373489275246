<script>
import { getUserActive } from '@/api/get'
import { cookies } from '@/api/index'

export default {
  methods: {
    setToCoockie () {
      getUserActive()
        .then(response => {
          if (response.id) cookies.set('ID', response.id)
          if (response.organization_id[0]) cookies.set('ORGANIZATION_ID', response.organization_id[0])
          if (response.photo_url) cookies.set('PHOTO', response.photo_url)

          console.log('org', cookies.get('ORGANIZATION_ID'))
        })
    }
  }
}
</script>
