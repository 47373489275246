<template>
<div class="input-style">
  <span class="error">{{ message }}</span>
  <input :id="id" :type="type" :value="value" :class="cl" placeholder="Введите значение" :readonly="readonly">
</div>
</template>

<script>
export default {
  name: 'input-comp',
  props: {
    message: { type: String, default: '' },
    id: { type: String, default: 'none' },
    type: { type: String, default: 'text' },
    value: { type: String, default: 'none' },
    cl: { type: String },
    readonly: { type: Boolean, default: false }
  }
}
</script>

<style scoped>
  .input-style {
    width: 100%;
  }
</style>
