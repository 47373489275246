<template>
  <div class="employes_body">
    <div class="container">
      <div class="container_visibility">
        <div class="header_filters">
            <input type="text" placeholder="Поиск..." class="search"  v-model="searchQuery">
          </div>
          <div class="employees">
            <div class="titles">
              <p>ФИО</p>
              <p>Отдел</p>
              <!-- <p>Статус</p>
              <p>Дата создания</p> -->
            </div>
            <transition-group name="report">
            <div v-for="employee in employees" :key="employee.id" class="report"
              @click="openReportDetails(report.id)">
              <div class="report_text">
                <p>{{ employee.user_name }}</p>
                <!-- <p>{{ report.creator_name }} {{ report.creator_surname }} {{ report.creator_patronymic }}</p>
                <p>
                  <span :class="getClass(report.status)">{{ getStatus(report.status) }}</span>
                </p>
                <p>{{ new Date(report.created_at).toLocaleDateString('ru-RU') }}</p> -->
              </div>
            </div>
          </transition-group>
          </div>
        </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable */
import { Header } from '@/api/index'
import axios from 'axios'
import WorkingStatusComp from '@/components/LkMasterPage/WorkingStatusComp.vue';

export default {
  components: {
    WorkingStatusComp,
  },
  data() {
    return {
      employees: []
    }
  },
  async mounted () {
    await this.fetchEmployees()
  },
  methods: {
    async fetchEmployees () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}employees/`, {
          headers: Header.auth
        })
        this.employees = response.data
      } catch (error) {
      }
    }
  }
}
</script>

<style scoped>
  .employes_body {
    display:flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    position: relative;
    width: 100%;
    left: 100px;
  }
  .container {
    position: relative;
    display:flex;
    flex-wrap:nowrap;
    flex-direction: column;
    top:15px;
    right:50px;
    width: 1734px;
    height: 929px;
    background: #5B5B60;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px 25px 0px 0px;
  }
  .container_visibility{
    width:100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
}
.header_filters{
    position: relative;
    display:flex;
    width:100%;
    top:50px;
    left:70px;
  }
  .search {
  position: relative;
  width: 246px;
  height: 38px;
  left:5px;
  top:-30px;
  background: #D9D9D9;
  border:none;
  border-radius: 5px;
}
.employees{
  display:flex;
  flex-wrap:nowrap;
  flex-direction: column;
  position: relative;
  top:40px;
  display:flex;
  width:95%;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ECC22C;

}
.titles{
    margin-left:180px;
    width:100%;
    display:flex;
    gap: 23%;
}
.titles p{
 font-family: 'Mulish';
 font-style: normal;
 font-weight: 600;
 font-size: 17.9075px;
 color: #FFFFFF;
}
.report{
  display:flex;
  justify-content: left;
  align-items: center;
  width: 1582px;
  height: 58px;
  background: #6A6A70;
  border-radius: 1.41768px;
  margin-top:30px;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}
  </style>
