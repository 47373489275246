<template>
  <div class="container">
    <div class="list">
      <input
        class="input_search"
        type="text"
        placeholder="Поиск..."
        v-model="username"
        style="margin:0; padding:0; display:inline-block;"
      />
      <button class="btnAdd" @click="changeVisibleFilter">Фильтр</button>
      <button class="btnAdd" @click="changeVisibleCreateModal">Создать отчет</button>
      <div class="position_btn" style="display:inline-block; margin:0;">
        <select id="select" class="select" v-model="select">
          <option value="false">Обычные отчеты</option>
          <option value="true">Заблокированные</option>
          <option value="all">Все отчеты</option>
        </select>
      </div>
      <div class="list__title">
        <div class="list__titleItem">Id</div>
        <div class="list__titleItem">Название</div>
        <div class="list__titleItem">Статус</div>
        <div class="list__titleItem">Дата создания</div>
        <div class="list__titleItem">Действия</div>
      </div>
      <modal class="popup_block_format" v-if="visibleFilter">
        <div class="formes">
          <div class="form">
            <label for="processed_at">3а период с:</label>
            <input
              id="from_date"
              type="date"
              class="input"
              v-model="dateFrom"
            />
          </div>
          <div class="form">
            <label for="processed_at">По</label>
            <input
              id="to_date"
              type="date"
              class="input"
              v-model="dateTo"
            />
          </div>
        </div>
      </modal>
      <div v-if="this.$store.state.LIST_USERS" class="scroll">
        <!-- список всех отчетов -->
        <div class="scroll">
          <a class="row" v-for="report in filteredReports.filter((report) => report.title.toLowerCase().includes(this.username.trim().toLowerCase()))" :key="report.id">
            <div class="usersInfo">
              <div class="usersInfo_line">
                <div class="col">{{ report.id }}</div>
                <div class="col">{{ report.title }}</div>
                <div class="col">{{ report.status }}</div>
                <div id="list__titleItem">{{ report.created_at }}</div>
                <div class="btns" style="width:100px;">
                  <button id="bord_red" class="btn" style="background-color:transparent;" @click="changeVisibleBlockModal(report)"></button>
                  <button id="bord_green" class="btn" style="background-color:transparent;" @click="changeVisibleEditModal(report)"></button>
                </div>
              </div>
            </div>
            <!-- modal -->
            <div v-if="visibleEditModal" class="add add_container">
              <div class="add_body">
                <div class="add_close">
                  <img id="close_1" src="@/assets/images/close.png" alt="close" @click="changeVisibleEditModal"/>
                </div>
                <p>Редактирование отчета</p>
                <div id="form" class="add_form">
                  <div>
                    <label>Название:</label>
                    <div>
                      <span class="error">{{ err.title }}</span>
                      <input type="text" v-model="reportActiveObject.title" placeholder="Введите значение">
                    </div>
                  </div>
                  <div>
                    <label>Текст:</label>
                    <div>
                      <span class="error">{{ err.text }}</span>
                      <input type="text" v-model="reportActiveObject.text" placeholder="Введите значение">
                    </div>
                  </div>
                </div>
                <button id="add" class="add_btn" @click="editReport(report)">Редактировать</button>
              </div>
            </div>
            <div v-if="visibleBlockModal" class="state state_container">
              <div class="state_body">
                <div class="state_close">
                  <img id="close_1" src="@/assets/images/close.png" @click="changeVisibleBlockModal">
                </div>
                <p>Удалить отчет</p>
                <div class="state_btns">
                  <button id="delete" @click="deleteReport">Удалить</button>
                  <button id="close_2" @click="changeVisibleBlockModal">Закрыть</button>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Окно создания отчета -->
  <div v-if="visibleCreateModal" class="add add_container">
    <div class="add_body">
      <div class="add_close">
        <img id="close_1" src="@/assets/images/close.png" alt="close" @click="changeVisibleCreateModal"/>
      </div>
      <p>Создание отчета</p>
      <div id="form" class="add_form">
        <div>
          <label>Название:</label>
          <div>
            <span class="error">{{ err.title }}</span>
            <input type="text" v-model="createReportObject.title" placeholder="Введите значение">
          </div>
        </div>
        <div>
          <label>Текст:</label>
          <div>
            <span class="error">{{ err.text }}</span>
            <input type="text" v-model="createReportObject.text" placeholder="Введите значение">
          </div>
        </div>
      </div>
      <button id="add" class="add_btn" @click="createReport">Создать</button>
    </div>
  </div>
</template>

<script>
import { validInputText } from '@/validation'
import { getAllReports } from '@/api/get'
import { patchEditReport, patchDeleteReport } from '@/api/patch'
import { postCreateReport } from '@/api/post'

export default {
  data () {
    return {
      username: '',
      select: false,
      visibleFilter: false,
      allReports: [],
      visibleEditModal: false,
      visibleBlockModal: false,
      visibleCreateModal: false,
      reportActiveObject: null,
      dateFrom: null,
      dateTo: null,
      createReportObject: {
        title: null,
        text: null
      },
      err: {
        title: null,
        text: null
      }
    }
  },
  mounted () {
    this.loadAllReports()
  },
  watch: {
    select () {
      this.loadAllReports()
    }
  },
  computed: {
    filteredReports () {
      if (!this.dateFrom || !this.dateTo) {
        return this.allReports
      }
      return this.allReports.filter(report =>
        report.created_at >= this.dateFrom && report.created_at <= this.dateTo
      )
    }
  },
  methods: {
    changeVisibleFilter () {
      this.visibleFilter = !this.visibleFilter
    },
    changeVisibleEditModal (report) {
      this.reportActiveObject = { ...report }
      this.visibleEditModal = !this.visibleEditModal
      this.err.title = null
      this.err.text = null
    },
    changeVisibleBlockModal (report) {
      this.reportActiveObject = { ...report }
      this.visibleBlockModal = !this.visibleBlockModal
    },
    changeVisibleCreateModal () {
      this.visibleCreateModal = !this.visibleCreateModal
      this.err.title = null
      this.err.text = null
    },
    editReport () {
      patchEditReport(this.reportActiveObject).then(() => {
        this.loadAllReports()
        this.changeVisibleEditModal()
      })
    },
    deleteReport () {
      patchDeleteReport(this.reportActiveObject).then(() => {
        this.loadAllReports()
        this.changeVisibleBlockModal()
      })
    },
    createReport () {
      this.err.title = validInputText(this.createReportObject.title)
      this.err.text = validInputText(this.createReportObject.text)
      if (!this.err.title && !this.err.text) {
        postCreateReport(this.createReportObject).then(() => {
          this.loadAllReports()
          this.changeVisibleCreateModal()
        })
      }
    },
    loadAllReports () {
      getAllReports(this.select).then(response => {
        this.allReports = response
      })
    }
  }
}
</script>

<style scoped>

span.error{
  top: auto;
  left: auto;
  display: block;
  max-width: 245px;
}
.input_search {
  margin-top: 11px;
  margin-left: 18px;
  padding: 10px;
  width: 250px;
  height: 40px;
  background: #d9d9d9;
  border-style: solid;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.list {
  position: relative;
  overflow-y: auto;
  width: 95%;
  height: 100vh;
  margin: 0 auto;
  flex: 1 0 auto;
  padding: 10px;
  padding-top: 0;
  margin-top: 20px;
}

.list__title {
  margin-top: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  font-family: "Mulish";
  color: #ffffff;
  display: flex;
  width: 85%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.formes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: unset;
  justify-content: space-around;
  /* background-color: rgb(54, 117, 117); */
  width: 500px;
  /* height: 50vh; */
  overflow-y: scroll;
}

.formes::-webkit-scrollbar {
  -webkit-appearance: none;
}

.formes::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: 1px solid #ecc22c;
  background-color: #6e6f77;
}

.form {
  /* background-color: aquamarine; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.btnAdd {
  cursor: pointer;
  width: 170px;
  height: 39px;
  font-family: "Monsterrat";
  font-weight: 700;
  font-size: 14px;
  border: none;
  color: black;
  margin-top: 25px;
  margin-left: 18px;
  border: 0;
  background: #ecc22c;
  border-radius: 5.53226px;
}
/*начало стили для input */
.input {
  margin-top: 5px;
  display: block;
  height: 38px;
  width: 200px;
  padding: 17px 20px;
  background: none;
  border-width: 0px 0px 3px 3px;
  border-style: solid;
  border-color: #ecc22c;
  border-radius: 5px;
  font-family: "Monsterrat";
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}
::-webkit-input-placeholder {
  color: #ffffff8b;
}
/*конец стили для input */
.btn_dowload {
  border: 1px solid #000000;
}
.popup_block_format {
  background-color: #bebebe;
  top: 5px;
  left: 15px;
  position: relative;
  border-radius: 8px;
  border-top: 4px solid #ecc22c;
  width: 600px;
  height: 160px;
  font-size: 20px;
  font-family: "Monsterrat";
  display: block;
  cursor: pointer;
}

.position_btn {
  display: flex;
  margin-left: 180px;
  margin-top: -32px;
}
/* стилизация чекбокса */
.checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox.style-c input {
  position: relative;
  left: 10%;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox.style-c input:checked ~ .checkbox__checkmark {
  background-color: #f7cb15;
}

.checkbox.style-c input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}

.checkbox.style-c:hover input ~ .checkbox__checkmark {
  background-color: #eee;
}

.checkbox.style-c:hover input:checked ~ .checkbox__checkmark {
  background-color: #f7cb15;
}

.checkbox.style-c .checkbox__checkmark {
  position: absolute;
  top: 0px;
  height: 20px;
  width: 20px;
  background-color: #eee;
  transition: background-color 0.25s ease;
  border-radius: 4px;
}

.checkbox.style-c .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #333;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}

.checkbox.style-c .checkbox__body {
  color: #333;
  line-height: 1.4;
  font-size: 16px;
}

/* конец стилизации чекбокса */
.btn {
  cursor: pointer;
  width: 170px;
  height: 39px;
  font-family: "Monsterrat";
  font-weight: 700;
  font-size: 14px;
  border: none;
  color: black;
  margin-top: -7px;
  margin-left: 18px;
  border: 0;
  background: #ecc22c;
  border-radius: 5.53226px;
}

.scroll {
  /* margin-top: -53px; */
  overflow-y: auto;
  height: 84%;
}

.col {
  width: auto;
  text-align: left;
  color: #ffffff;
}

.col img {
  width: 56px;
  height: 56px;
  border-radius: 30px;
}
.usersInfo {
  display: flex;
  width: 100%;
  height: 70px;
  background-color: #bebebe;
  border-top: 3px solid #ecc22c;
  border-radius: 5px;
  align-items: center;
  margin-top: 20px;
}
.usersInfo_line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
