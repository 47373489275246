<template>
<div class="list">
  <div class="field">
    <input id="search" class="search" type="text" placeholder="Поиск..." v-model="text">
    <select id="select" class="select" @click="get_all_appeals" v-model="select">
      <option value="false">Обычные обращения</option>
      <option value="true">Заблокированные</option>
      <option value="all">Все обращения</option>
    </select>
    <button id="add" type="button" class="btnAdd" @click="show_add_window(true)">Cоздать обращение</button>
  </div>
  <div class="list__title">
    <div class="list__titleItem">Компания</div>
    <div class="list__titleItem">Объект</div>
    <div class="list__titleItem">Информация</div>
    <div class="list__titleItem">Действия</div>
  </div>
  <div class="scroll">
    <a class="row" v-for="item in appeals.filter((appeal) => `${appeal.company}${appeal.object}${appeal.info}`.toLowerCase().includes(this.text.trim().toLowerCase()))" :key="item.id">
      <div class="usersInfo">
        <div class="usersInfo_line">
          <div class="col">{{ item.company }}</div>
          <div class="col">{{ item.object }}</div>
          <div class="col">{{ item.info }}</div>
          <div class="btns">
            <button v-if="!item.is_blocked" id="bord_red" class="btn" @click="show_state_window(true, item)"></button>
            <button v-if="!item.is_blocked" id="bord_green" class="btn" @click="submit_appeal('change', item)"></button>
            <button v-else id="bord_lite" class="btn" @click="show_state_window(true, item)"></button>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>

<!-- Окно создания обращения -->
<div v-if="add_window" class="add add_container">
  <div class="add_body">
    <div class="add_close">
      <img id="close_1" src="@/assets/images/close.png" alt="close" @click="show_add_window(false)"/>
    </div>
    <p>Создание обращение</p>
    <div id="form" class="add_form">
      <div>
        <label>Клиент:</label>
        <select class="add_select" id="client" v-model="appeal.client_id">
          <option value="" disabled selected>Выберите из списка</option>
          <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }} - {{ user.surname }} - {{ user.patronymic }}</option>
        </select>
      </div>
      <div>
        <label>Тип обращения:</label>
        <select class="add_select" id="appeal_type" v-model="appeal.type">
          <option value="" disabled selected>Выберите из списка</option>
          <option v-for="item in types" :key="item.id" :value="item.title">{{ item.title }}</option>
        </select>
      </div>
      <div>
        <label>Компания клиента</label>
        <input-comp id="company" :value="appeal.company" :cl="'input_comp'" :message="error.company" @input="appeal.company = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Объект обращения</label>
        <input-comp id="object" :value="appeal.object" :cl="'input_comp'" :message="error.object" @input="appeal.object = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Информация об объекте</label>
        <input-comp id="info" :value="appeal.info" :cl="'input_comp'" :message="error.info" @input="appeal.info = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Стоимость обращения</label>
        <input-comp id="price" :value="appeal.price" :cl="'input_comp'" :message="error.price" @input="appeal.price = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Город</label>
        <input-comp id="city" :value="appeal.city" :cl="'input_comp'" :message="error.city" @input="appeal.city = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Улица</label>
        <input-comp id="street" :value="appeal.street" :cl="'input_comp'" :message="error.street" @input="appeal.street = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Квартира</label>
        <input-comp id="apart" :value="appeal.apart" :cl="'input_comp'" :message="error.apart" @input="appeal.apart = $event.target.value"></input-comp>
      </div>
      <button id="add" class="add_btn" @click="submit_appeal('add', appeal)">Создать</button>
    </div>
  </div>
</div>

<!-- Окно удаления / обращения -->
<div v-if="state_window" class="state state_container">
  <div class="state_body">
    <div class="state_close">
      <img id="close_1" src="@/assets/images/close.png" @click="show_state_window(false)">
    </div>
    <p v-if="!temp_appeal.is_blocked">Удалить обращение</p>
    <p v-else>Восстановить обращение</p>
    <div class="state_btns">
      <button v-if="!temp_appeal.is_blocked" id="delete" @click="change_appeal(temp_appeal)">Удалить</button>
      <button v-else id="recover" @click="change_appeal(temp_appeal)">Восстановить</button>
      <button id="close_2" @click="show_state_window(false)">Закрыть</button>
    </div>
  </div>
</div>
</template>

<script>
import { validText } from '@/validation'
import { getAllAppeals, getAllUsers } from '@/api/get'
import { postAddAppeal } from '@/api/post'
import { patchBlockAppeal, patchRecoverAppeal } from '@/api/patch'

export default {
  data () {
    return {
      add_window: false,
      state_window: false,
      text: '',
      select: 'false',
      appeals: [],
      users: [],
      types: [
        { id: '1', title: 'site' },
        { id: '2', title: 'visit' },
        { id: '3', title: 'call' },
        { id: '4', title: 'client_1c' }
      ],
      temp_appeal: {},
      appeal: {
        client_id: '',
        company: '',
        object: '',
        info: '',
        price: '',
        city: '',
        street: '',
        apart: '',
        type: ''
      },
      error: {
        company: '',
        object: '',
        info: '',
        price: '',
        city: '',
        street: '',
        apart: '',
        type: ''
      }
    }
  },
  methods: {
    show_add_window (value) {
      this.add_window = value
      if (value) {
        this.get_all_users()
      }
    },
    show_state_window (value, appeal = {}) {
      this.state_window = value
      this.temp_appeal = appeal
    },
    get_all_appeals () {
      getAllAppeals(this.select).then((response) => {
        this.appeals = response
      })
    },
    get_all_users () {
      getAllUsers('false').then((response) => {
        this.users = response
      })
    },
    submit_appeal (value, appeal) {
      const err = this.error
      err.company = validText(appeal.company)
      err.object = validText(appeal.object)
      err.info = validText(appeal.info)
      err.price = validText(appeal.price)
      err.city = validText(appeal.city)
      err.street = validText(appeal.street)
      err.apart = validText(appeal.apart)
      err.type = validText(appeal.type)
      if (!err.company && !err.object && !err.info && !err.price && !err.city && !err.street && !err.apart && !err.type) {
        if (value === 'add') {
          postAddAppeal(appeal).then((value) => {
            if (value) {
              this.get_all_appeals()
              for (const key in this.appeal) this.appeal[key] = ''
              this.show_add_window(false)
            }
          })
        }
        if (value === 'change') alert('В доработке...')
      }
    },
    change_appeal (appeal) {
      if (appeal.is_blocked) {
        patchRecoverAppeal(appeal.id).then((response) => {
          if (response) {
            this.get_all_appeals()
            this.show_state_window(false)
          }
        })
      } else {
        patchBlockAppeal(appeal.id).then((response) => {
          if (response) {
            this.get_all_appeals()
            this.show_state_window(false)
          }
        })
      }
    }
  },
  mounted () {
    this.get_all_appeals()
  }
}
</script>

<!-- <template>
  <p>Редактировать обращение</p>
      <div class="form">
        <label for="processed_at">В обработке с:</label>
        <input-comp
          id="processed_at"
          :type="'date'"
          :name="'processed_at'"
          :value="DataAppeal.processed_at">
        </input-comp>
      </div>
      <div class="form">
        <label for="working_at">В работе с:</label>
        <input-comp
          id="working_at"
          :type="'date'"
          :name="'working_at'"
          :value="DataAppeal.working_at">
        </input-comp>
      </div>
      <div class="form">
        <label for="finished_at">Завершено с:</label>
        <input-comp
          id="finished_at"
          :type="'date'"
          :name="'finished_at'"
          :value="DataAppeal.finished_at">
        </input-comp>
      </div>
      <div class="form">
        <label for="closed_at">Закрыто с:</label>
        <input-comp
          id="closed_at"
          :type="'date'"
          :name="'closed_at'"
          :value="DataAppeal.closed_at">
        </input-comp>
      </div>
      <div class="form">
        <label for="called_at">Обзвон:</label>
        <input-comp
          id="called_at"
          :type="'date'"
          :name="'called_at'"
          :value="DataAppeal.called_at">
        </input-comp>
      </div>
      <div class="form">
        <label for="is_blocked">Заблокирован:</label>
        <input-comp
          id="is_blocked"
          :type="'text'"
          :name="'is_blocked'"
          :value="DataAppeal.is_blocked ? 'ДА': 'НЕТ'"
          readonly>
        </input-comp>
      </div>
    </div>
  <div>
    <button v-if="!DataAppeal.is_blocked && !DataAppeal.processed_at" class="btn_action" @click="process_appeal">Взять в обработку</button>
    <button v-else class="btn_action" disabled>Взять в обработку</button>
    <button v-if="!DataAppeal.is_blocked && !DataAppeal.working_at && DataAppeal.processed_at" class="btn_action" @click="work_appeal">Взять в работу</button>
    <button v-else class="btn_action" disabled>Взять в работу</button>
    <button v-if="!DataAppeal.is_blocked && !DataAppeal.finished_at && DataAppeal.working_at" class="btn_action" @click="finish_appeal">Завершить работу</button>
    <button v-else class="btn_action" disabled>Завершить работу</button>
    <button v-if="!DataAppeal.is_blocked && !DataAppeal.closed_at && DataAppeal.finished_at" class="btn_action" @click="close_appeal">Закрыть</button>
    <button v-else class="btn_action" disabled>Закрыть</button>
    <button v-if="!DataAppeal.is_blocked" class="btn_action" @click="block_appeal">Блокировать</button>
    <button v-else class="btn_action" disabled>Блокировать</button>
    <button v-if="DataAppeal.is_blocked" class="btn_action" @click="unblock_appeal">Разблокировать</button>
    <button v-else class="btn_action" disabled>Разблокировать</button>
    <div>
      <span>Доступно через 6 дней после закрытия обращения</span>
      <button class="btn_action" @click="call_appeal" disabled>Позвонить</button>
    </div>
  </div>
  <button @click="submit" class="btn">Редактировать</button>
</div>
</div>
</div>
</template>

<script>
import {
patchAppealProcess,
patchAppealWork,
patchAppealFinish,
patchAppealClose,
patchAppealCall,
patchAppealBlock,
patchAppealUnblock
} from '@/api/patch'

export default {
methods: {
hideChangeAppeal () {
this.$store.dispatch('deleteApppeal')
this.$parent.change_appeal()
},
process_appeal () {
patchAppealProcess(this.DataAppeal.id).then(() => { this.$store.dispatch('changeAppeal') })
},
work_appeal () {
patchAppealWork(this.DataAppeal.id).then(() => { this.$store.dispatch('changeAppeal') })
},
finish_appeal () {
patchAppealFinish(this.DataAppeal.id).then(() => { this.$store.dispatch('changeAppeal') })
},
close_appeal () {
patchAppealClose(this.DataAppeal.id).then(() => { this.$store.dispatch('changeAppeal') })
},
call_appeal () {
patchAppealCall(this.DataAppeal.id).then(() => { this.$store.dispatch('changeAppeal') })
},
block_appeal () {
patchAppealBlock(this.DataAppeal.id).then(() => { this.$store.dispatch('changeAppeal') })
},
unblock_appeal () {
patchAppealUnblock(this.DataAppeal.id).then(() => { this.$store.dispatch('changeAppeal') })
},
submit () {
this.errors.company = validCompany(this.DataAppeal.company)
this.errors.object = validObejct(this.DataAppeal.object)
this.errors.info = validInfo(this.DataAppeal.info)
this.errors.city = validCity(this.DataAppeal.city)
this.errors.street = validStreet(this.DataAppeal.street)
this.errors.apart = validApart(this.DataAppeal.apart)
if (!this.errors.company && !this.errors.object && !this.errors.info && !this.errors.city && !this.errors.street && !this.errors.apart) {
  // patchAppealData(this.DataAppeal).then(() => { this.$parent.change_appeal() })
  console.log(this.DataAppeal)
}
}
}
}
</script> -->
