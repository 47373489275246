import { createRouter, createWebHistory } from 'vue-router'
import { getUserActive } from '@/api/get'
import { cookies } from '@/api/index'
import UserPageComp from '@/components/LkSysAdminPage/UserPageComp.vue'
import EmployeePageComp from '@/components/LkSysAdminPage/EmployeePageComp.vue'
import AppealPageComp from '@/components/LkSysAdminPage/AppealPageComp.vue'
import CommentPageComp from '@/components/LkSysAdminPage/CommentPageComp.vue'
import RolePageComp from '@/components/LkSysAdminPage/RolePageComp.vue'
import DepartamentPageComp from '@/components/LkSysAdminPage/DepartamentPageComp.vue'
import DirectionPageComp from '@/components/LkSysAdminPage/DirectionPageComp.vue'
import EstimePageComp from '@/components/LkSysAdminPage/EstimePageComp.vue'
import Window5Comp from '@/components/LkSysAdminPage/Window5Comp.vue'
import AnalyticsPageComp from '@/components/LkSysAdminPage/AnalyticsPageComp.vue'
import UserDashBoardComp from '@/components/WorkSpace/UserDashBoardComp.vue'
import UserMonitioringComp from '@/components/LkMasterPage/UserMonitoringComp.vue'
import DepartamentComp from '@/components/LkMasterPage/DepartamentComp.vue'
import ReportsComp from '@/components/LkMasterPage/ReportsComp.vue'
import WorkTimeComp from '@/components/LkMasterPage/WorkTimeComp.vue'
import NewsPageComp from '@/components/LkSysAdminPage/NewsPageComp.vue'
import OrganizationManagement from '@/views/OrganizationManagement.vue'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('@/views/MainView.vue')
  },
  {
    path: '/messenger',
    name: 'Messenger',
    component: () => import('@/views/MessengerView.vue'),
    meta: { auth: true }
  },
  {
    path: '/workspace',
    name: 'Workspace',
    component: () => import('@/views/WorkSpaceView.vue'),
    children: [
      {
        path: '/Workspace/PersonalDashboard',
        name: 'Workspace/PersonalDashboard',
        component: UserDashBoardComp,
        props: true
      }
    ],
    meta: { auth: true }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/RegisterView.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/SettingsView.vue'),
    meta: { auth: true }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/Account.vue'),
    meta: { auth: true }
  },
  {
    path: '/MobilePrototype',
    name: 'MobilePrototype',
    component: () => import('@/components/WorkSpaceMobile/MobileUserDashBoardComp.vue'),
    meta: { auth: true }
  },
  {
    path: '/organization_management',
    name: 'organization_management',
    component: OrganizationManagement,
    meta: { auth: true }
  },
  {
    path: '/organization_user_info',
    name: 'organization_user_info',
    component: () => import('@/views/OrganizationUsersInfo.vue'),
    meta: { auth: true }
  },
  {
    path: '/user_table',
    name: 'user_table',
    component: () => import('@/views/UserTable.vue'),
    meta: { auth: true }
  },
  {
    path: '/organization_table',
    name: 'organization_table',
    component: () => import('@/views/OrganizationTable.vue'),
    meta: { auth: true }
  },
  {
    path: '/organization_table_list',
    name: 'organization_table_list',
    component: () => import('@/views/OrganizationTableList.vue'),
    meta: { auth: true }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/views/Transactions.vue'),
    meta: { auth: true }
  },
  {
    path: '/transactions2',
    name: 'transactions2',
    component: () => import('@/views/Transactions2.vue'),
    meta: { auth: true }
  },
  {
    path: '/tasks_list',
    name: 'Tasks_list',
    component: () => import('@/views/TaskListView.vue'),
    meta: { auth: true },
    props: true
  },
  {
    path: '/LkMaster',
    name: 'LkMaster',
    component: () => import('@/views/LkMasterView.vue'),
    children: [
      {
        path: '/LkMaster/UserMonitor',
        name: 'LkMaster/UserMonitor',
        component: UserMonitioringComp,
        props: true
      },
      {
        path: '/LkMaster/Departaments',
        name: 'LkMaster/Departaments',
        component: DepartamentComp,
        props: true
      },
      {
        path: '/LkMaster/Reports',
        name: 'LkMaster/Reports',
        component: ReportsComp,
        props: true
      },
      {
        path: '/LkMaster/WorkTime',
        name: 'LkMaster/WorkTime',
        component: WorkTimeComp,
        props: true
      }
    ],
    meta: { auth: true }
  },
  {
    path: '/LkSysAdmin',
    name: 'LkSysAdmin',
    component: () => import('../views/LkSysAdminView.vue'),
    children: [
      {
        path: '/LkSysAdmin/Users',
        name: 'LkSysAdmin/Users',
        component: UserPageComp,
        props: true
      },
      {
        path: '/LkSysAdmin/Employees',
        name: 'LkSysAdmin/Employees',
        component: EmployeePageComp,
        props: true
      },
      {
        path: '/LkSysAdmin/Appeals',
        name: 'LkSysAdmin/Appeals',
        component: AppealPageComp,
        props: true
      },
      {
        path: '/LkSysAdmin/Reports',
        name: 'LkSysAdmin/Reports',
        component: Window5Comp,
        props: true
      },
      {
        path: '/LkSysAdmin/Analytics',
        name: 'LkSysAdmin/Analytics',
        component: AnalyticsPageComp,
        props: true
      },
      {
        path: '/LkSysAdmin/Comments',
        name: 'LkSysAdmin/Comments',
        component: CommentPageComp,
        props: true
      },
      {
        path: '/LkSysAdmin/Roles',
        name: 'LkSysAdmin/Roles',
        component: RolePageComp,
        props: true
      },
      {
        path: '/LkSysAdmin/Departaments',
        name: 'LkSysAdmin/Departaments',
        component: DepartamentPageComp,
        props: true
      },
      {
        path: '/LkSysAdmin/Direction',
        name: 'LkSysAdmin/Direction',
        component: DirectionPageComp,
        props: true
      },
      {
        path: '/LkSysAdmin/Evaluations',
        name: 'LkSysAdmin/Evaluations',
        component: EstimePageComp,
        props: true
      },
      {
        path: '/LkSysAdmin/NewsPageComp',
        name: 'LkSysAdmin/NewsPageComp',
        component: NewsPageComp,
        props: true
      }
    ],
    meta: { auth: true }
  },
  {
    path: '/UserInfo/:id',
    name: 'UserInfo',
    component: () => import('@/views/UserInfoView.vue'),
    meta: { auth: true }
  },
  {
    path: '/LkUser',
    name: 'LkUser',
    component: () => import('@/views/LkUserView.vue'),
    meta: { auth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (cookies.get('JWT')) getUserActive()
  if (to.matched.some((record) => record.meta.auth)) {
    if (cookies.get('JWT')) next()
    else next('/')
  } else next()
})

export default router
