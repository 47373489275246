<template>
<div class="list">
  <div class="field">
    <input id="search" class="search" type="text" placeholder="Поиск..." v-model="text">
    <select id="select" class="select" @click="get_all_estimates" v-model="select">
      <option value="false">Обычные оценки</option>
      <option value="true">Заблокированные</option>
      <option value="all">Все оценки</option>
    </select>
    <button id="add" type="button" class="btnAdd" @click="show_add_window(true)">Cоздать оценку</button>
  </div>
  <div class="list__title">
    <div class="list__titleItem">Плюсы</div>
    <div class="list__titleItem">Минусы</div>
    <div class="list__titleItem">Баллы</div>
    <div class="list__titleItem">Действия</div>
  </div>
  <div class="scroll">
    <a class="row" v-for="item in estimates.filter((estime) => `${estime.positive}${estime.negative}`.toLowerCase().includes(this.text.trim().toLowerCase()))" :key="item.id">
      <div class="usersInfo">
        <div class="usersInfo_line">
          <div class="col">{{ item.positive }}</div>
          <div class="col">{{ item.negative }} </div>
          <div class="col">{{ item.score }} </div>
          <div class="btns">
            <button v-if="!item.is_blocked" id="bord_red" class="btn" @click="show_state_window(true, item)"></button>
            <button v-if="!item.is_blocked" id="bord_green" class="btn" @click="submit_estime('change', item)"></button>
            <button v-else id="bord_lite" class="btn" @click="show_state_window(true, item)"></button>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>

<!-- Окно создания оценки -->
<div v-if="add_window" class="add add_container">
  <div class="add_body">
    <div class="add_close">
      <img id="close_1" src="@/assets/images/close.png" alt="close" @click="show_add_window(false)"/>
    </div>
    <p>Создание оценки</p>
    <div id="form" class="add_form">
      <div>
        <label>Клиент:</label>
        <select class="add_select" v-model="estime.client_id">
          <option value="" disabled selected>Выберите из списка</option>
          <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }} - {{ user.surname }} - {{ user.patronymic }}</option>
        </select>
      </div>
      <div>
        <label>Обращение:</label>
        <select class="add_select" v-model="estime.appeal_id">
          <option value="" disabled selected>Выберите из списка</option>
          <option v-for="appeal in appeals" :key="appeal.id" :value="appeal.id">{{ appeal.id }}</option>
        </select>
      </div>
      <div>
        <label>Плюсы оценки:</label>
        <input-comp id="positive" :value="estime.positive" :cl="'input_comp'" :message="error.positive" @input="estime.positive = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Минусы оценки:</label>
        <input-comp id="negative" :value="estime.negative" :cl="'input_comp'" :message="error.negative" @input="estime.negative = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Балл (от 1 до 10)</label>
        <input-comp id="score" :type="number" :value="estime.score" :cl="'input_comp'" :message="error.score" @input="estime.score = $event.target.value"></input-comp>
      </div>
    </div>
    <button id="add" class="add_btn" @click="submit_estime('add', estime)">Создать</button>
  </div>
</div>

<!-- Окно редактирования оценки -->
<div v-if="change_window" class="add add_container">
  <div class="add_body">
    <div class="add_close">
      <img id="close_1" src="@/assets/images/close.png" alt="close" @click="show_change_window(false)"/>
    </div>
    <p>Редактирование оценки</p>
    <div id="form" class="add_form">
      <div>
        <label>Клиент:</label>
        <select class="add_select" v-model="temp_estime.client_id">
          <option value="" disabled selected>Выберите из списка</option>
          <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }} - {{ user.surname }} - {{ user.patronymic }}</option>
        </select>
      </div>
      <div>
        <label>Обращение:</label>
        <select class="add_select" v-model="temp_estime.appeal_id">
          <option value="" disabled selected>Выберите из списка</option>
          <option v-for="appeal in appeals" :key="appeal.id" :value="appeal.id">{{ appeal.id }}</option>
        </select>
      </div>
      <div>
        <label>Плюсы оценки:</label>
        <input-comp id="positive" :value="temp_estime.positive" :cl="'input_comp'" :message="error.positive" @input="temp_estime.positive = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Минусы оценки:</label>
        <input-comp id="negative" :value="temp_estime.negative" :cl="'input_comp'" :message="error.negative" @input="temp_estime.negative = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Балл (от 1 до 10):</label>
        <input-comp id="score" :type="number" :value="temp_estime.score" :cl="'input_comp'" :message="error.score" @input="temp_estime.score = $event.target.value"></input-comp>
      </div>
    </div>
    <button id="add" class="add_btn" @click="submit_estime('change', temp_estime)">Редактировать</button>
  </div>
</div>

<!-- Окно удаления / восстановления оценки -->
<div v-if="state_window" class="state state_container">
  <div class="state_body">
    <div class="state_close">
      <img id="close_1" src="@/assets/images/close.png" @click="show_state_window(false)">
    </div>
    <p v-if="!temp_estime.is_blocked">Удалить оценку</p>
    <p v-else>Восстановить оценку</p>
    <div class="state_btns">
      <button v-if="!temp_estime.is_blocked" id="delete" @click="change_estime(temp_estime)">Удалить</button>
      <button v-else id="recover" @click="change_estime(temp_estime)">Восстановить</button>
      <button id="close_2" @click="show_state_window(false)">Закрыть</button>
    </div>
  </div>
</div>
</template>

<script>
import { validText } from '@/validation'
import { getAllEstimates, getAllUsers, getAllAppeals } from '@/api/get'
import { postAddEstime } from '@/api/post'
import { patchChangeEstime, patchBlockEstime, patchRecoverEstime } from '@/api/patch'

export default {
  data () {
    return {
      add_window: false,
      state_window: false,
      text: '',
      select: 'false',
      estimates: [],
      users: [],
      appeals: [],
      temp_estime: {},
      estime: {
        client_id: '',
        appeal_id: '',
        positive: '',
        negative: '',
        score: 1
      },
      error: { positive: '', negative: '' }
    }
  },
  methods: {
    show_add_window (value) {
      for (const key in this.error) this.error[key] = ''
      this.add_window = value
      if (value) {
        this.get_all_users()
        this.get_all_appeals()
      }
    },
    show_change_window (value) {
      for (const key in this.error) this.error[key] = ''
      this.change_window = value
      if (value) {
        this.get_all_users()
        this.get_all_appeals()
      }
    },
    show_state_window (value, estime = {}) {
      this.state_window = value
      this.temp_estime = estime
    },
    get_all_estimates () {
      getAllEstimates(this.select).then((response) => {
        this.estimates = response
      })
    },
    get_all_users () {
      getAllUsers('false').then((response) => {
        this.users = response
      })
    },
    get_all_appeals () {
      getAllAppeals('false').then((response) => {
        this.appeals = response
      })
    },
    submit_estime (value, estime) {
      const err = this.error
      err.positive = validText(estime.positive)
      err.negative = validText(estime.negative)
      if (!err.positive && !err.negative) {
        if (value === 'add') {
          postAddEstime(estime).then((value) => {
            if (value) {
              this.get_all_estimates()
              for (const key in this.estime) this.estime[key] = ''
              this.show_add_window(false)
            }
          })
        }
        if (value === 'change') {
          patchChangeEstime(estime).then((value) => {
            if (value) {
              this.get_all_estimates()
              this.temp_estime = {}
              this.show_change_window(false)
            }
          })
        }
      }
    },
    change_estime (estime) {
      if (estime.is_blocked) {
        patchRecoverEstime(estime.id).then((response) => {
          if (response) {
            this.get_all_estimates()
            this.show_state_window(false)
          }
        })
      } else {
        patchBlockEstime(estime.id).then((response) => {
          if (response) {
            this.get_all_estimates()
            this.show_state_window(false)
          }
        })
      }
    }
  },
  mounted () {
    this.get_all_estimates()
  }
}
</script>
