<template>
<div class="container" v-if="show">
    <div class="calendar" v-if="!showForm">
      <div class="header">
        {{ currentMonth }} {{ currentDate.getFullYear() }}
        <div class="week">
         <div @click="hideWindow" class="weekday">Пн</div>
         <div class="weekday">Вт</div>
         <div class="weekday">Ср</div>
         <div class="weekday">Чт</div>
         <div class="weekday">Пт</div>
         <div class="weekend">Сб</div>
         <div class="weekend">Вс</div>
        </div>
        </div>
        <!-- <button @click="nextMonth"></button>
        {{ currentMonth }} {{ currentDate.getFullYear() }}
        <span>Сегодня {{ currentDate.getDate() }} {{ todayMonthInGenitiveCase }}</span>
        <div v-if="selectedDay">
          Выбранный день - {{ selectedDay }} {{ currentMonthInNominativeCase }}
          <button class="add-button" @click="openForm(formattedDate)">Провести учет</button>
        </div> -->

      <div class="days">
        <div
          class="day"
          v-for="day in days"
          :class="{ 'today': day.isToday, 'selected': day.date === selectedDay, 'highlight': day.highlight }"
          :key="day.date"
          @click="selectDay(day.date)"
        >
          {{ day.date }}
        </div>
      </div>
      <div class="buttons">
        <div class="dates_wrapper">
          <div class="dates">
        <span>Сегодня {{ currentDate.getDate() }} {{ todayMonthInGenitiveCase }}</span>
            <div v-if="selectedDay">
               Выбранный день - {{ selectedDay }} {{ currentMonthInNominativeCase }}
              </div>
            <div v-else>
          Выберите день
         </div>
           </div>
           <button v-if="selectedDay" class="add-button" @click="openForm(formattedDate)">Провести учет</button>
            </div>
    </div>
    </div>
    <div v-if="showForm">
      <WorkingStatusComp :date="selectedDay" :formattedDate="formattedDate" @close="closeForm"></WorkingStatusComp>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import WorkingStatusComp from '@/components/LkMasterPage/WorkingStatusComp.vue';

export default {
  components: {
    WorkingStatusComp,
  },
  data() {
    return {
      currentDate: new Date(),
      currentMonth: '',
      currentMonthInNominativeCase: '',
      days: [],
      selectedDay: null,
      todayMonthInGenitiveCase: '',
      showForm: false,
      formattedDate: '',
    };
  },
  props: {
    show: { type: Boolean, default: false },
  },
  computed: {
    getYear() {
      let year = this.currentDate.getFullYear();
      if (this.currentDate.getMonth() === 0 && this.currentMonth === 'Декабрь') {
        year++;
      }

      return year;
    },
  },
  mounted() {
    this.generateCalendar();
    const monthNames = [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ];
    this.currentMonth = monthNames[this.currentDate.getMonth()];
    this.currentMonthInNominativeCase = this.getMonthNameInNominativeCase(
      this.currentMonth
    );
    this.todayMonth = monthNames[this.currentDate.getMonth()];
    this.todayMonthInGenitiveCase = this.getMonthNameInGenitiveCase(
      this.todayMonth
    );
  },
  methods: {
    getMonthNameInNominativeCase(monthName) {
      const lastLetter = monthName.slice(-1);
      if (lastLetter === 'ь') {
        return monthName.slice(0, -1) + 'я';
      } else if (lastLetter === 'й') {
        return monthName.slice(0, -1) + 'я';
      } else if (lastLetter === 'я') {
        return monthName;
      } else {
        return monthName + 'а';
      }
    },
    getMonthNameInGenitiveCase(monthName) {
      const lastLetter = monthName.slice(-1);
      if (lastLetter === 'ь') {
        return monthName.slice(0, -1) + 'я';
      } else if (lastLetter === 'й') {
        return monthName.slice(0, -1) + 'я';
      } else if (lastLetter === 'а') {
      } else {
        return monthName + 'а';
      }
    },
    generateCalendar() {
      this.days = [];
      const firstDay = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
      );
      const lastDay = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        0
      );
      const monthNames = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ];

      for (let i = 1; i <= lastDay.getDate(); i++) {
        const date = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth(),
          i
        );
        const isToday =
          date.toDateString() === new Date().toDateString();
        const isSelected =
          date.toDateString() === new Date(this.selectedDay).toDateString() && !isToday;
        const isHighlighted = date.getDay() === 5 || date.getDay() === 6; // highlight Saturday and Sunday
        this.days.push({ date: i, isToday, selected: isSelected, highlight: isHighlighted });
      }
      // форматируем дату
      if (this.selectedDay) {
        const year = this.currentDate.getFullYear();
        const month = (this.currentDate.getMonth() + 1).toString().padStart(2, '0');
        const date = this.selectedDay.toString().padStart(2, '0');
        this.formattedDate = `${date}.${month}.${year}`;
      }

      this.currentMonth = monthNames[this.currentDate.getMonth()];
      this.currentMonthInNominativeCase = this.getMonthNameInNominativeCase(
        this.currentMonth
      );
      this.todayMonth =
        monthNames[new Date().getMonth()];
      this.todayMonthInGenitiveCase = this.getMonthNameInGenitiveCase(
        this.todayMonth
      );
    },
    previousMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() - 1);
      this.selectedDay = null;
      this.generateCalendar();
      this.getYear();
    },
    nextMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() + 1);
      this.selectedDay = null;
      this.generateCalendar();
      this.getYear();
    },
    selectDay(day) {
      if (this.selectedDay !== day) {
        this.selectedDay = day;
        this.generateCalendar();
      }
    },
    openForm(formattedDate) {
      this.showForm = true;
      this.formattedDate = formattedDate;
    },
    closeForm() {
      this.showForm = false;
    },
    hideWindow () {
      this.$emit('update:show', false)
    },
  },
};
</script>

<style scoped>
  .body {
    display:flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    position: relative;
    width: 100%;
    left: 100px;
  }
  .container {
    position:fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index: 30;
  }

  .calendar {
    border: 1px solid #ccc;
    border-radius: 30px;
    width:1158px;
    height: 744px;
    margin: 50px auto;
    font-family: Arial, sans-serif;
    background-color: #1e1e1ee5;
  }

  .header {
    font-size: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    margin-bottom: 10px;
    width:100%;
    background: #ECC22C;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    border-radius: 25px 25px 0px 0px;
    z-index: 0;
  }
  .week {
    display:flex;
    width:100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .days {
    display: flex;
    flex-wrap: wrap;
    z-index: 10;
  }

  .weekday {
    width: calc(100% / 7);
    text-align: center;
    color:rgb(0, 0, 0);
    font-size:40px;
    padding: 10px;
  }

  .weekend {
    width: calc(100% / 7);
    text-align: center;
    padding: 10px;
    color: red;
    font-size:40px;
  }

  .day {
    width: calc(100% / 7);
    color:white;
    text-align: center;
    padding: 20px;
    cursor: pointer;
    font-size: 40px;
  }

  .selected {
    background-color: yellow;
    border-radius: 60px;
    color: black;
    transition: background-color 0.3s ease-in-out;
  }

  .today {
    background-color: #ccc;
    border-radius:60px;
  }

  .add-button {
    margin-left: 20px;
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
  .buttons{
    display:flex;
    width:100%;
    justify-content: center;
    align-items: center;
  }
  .dates_wrapper{
    display:flex;
    position: relative;
    width:90%;
    border-top: 1px solid #ECC22C;

  }
  .dates{
    display:flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  </style>
