<template>
  <div class="modal">
        <div class="modal_body">
          <div class="modal_body_part_1">
          <div class="modal_body_left">
          <img :src="getImgUrl(photo.photo_url)" alt="Ошибка изображение" :width="500">
          </div>
          <div class="modal_body_right">
            <div class="modal_body_right_header">
              <a><img @click="closePhoto" src="@/assets/images/Workspace/CloseWhite.png"></a>
            </div>
            <div class="modal_body_right_comments">
                <div class="comment_box" v-for="(comment) in photo_comments" :key="comment.id">
                    <div class="comment_text">
                    <img class="user_photo" :src="getImgUrl(comment.user_photo_url)" alt="Ошибка изображение">
                    <p>{{ comment.text }}</p>
                    </div>
                    <div class="comment_date">
                    <p>{{ new Date(comment.created_at).toLocaleDateString('ru-RU')}}</p>
                </div>
                </div>
            </div>
            <div class="modal_body_right_send">
              <img class="user_photo" :src="getImgUrl(user.photo_url)" alt="Ошибка изображение">
              <textarea
              class="comment"
              type="textarea"
              v-model="newComment"
              @keydown.enter.prevent="addComment"
              placeholder="Добавить комментарий..."></textarea>
            </div>
          </div>
        </div>
          <div class="modal_description">
            <p>Дата публикации: {{ new Date(photo.created_at).toLocaleDateString('ru-RU')}}</p>
            <p>Описание: {{ photo.text }}</p>
          </div>
        </div>
      </div>
</template>

<script>
import { getUserActive } from '@/api/get'
import { Header } from '@/api/index'
import axios from 'axios'
export default {
  data () {
    return {
      photo: {},
      user: {},
      photo_comments: [],
      newComment: '',
      id: this.photoId
    }
  },
  props: {
    photoId: {
      type: String,
      required: true
    }
  },
  async created () {
    await this.fetchPhoto()
    await this.getUser()
    await this.fetchComments()
  },
  methods: {
    async getUser () {
      const response = await getUserActive()
      this.user = response
    },
    async fetchPhoto () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}photos/${this.id}`, {
          headers: Header.auth
        })
        this.photo = response.data
      } catch (error) {
      }
    },
    async fetchComments () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}comments/`, {
          headers: Header.auth,
          params: { photo_id: this.id }
        })
        this.photo_comments = response.data
      } catch (error) {
      }
    },
    async addComment () {
      try {
        const response = await axios.post(`${process.env.VUE_APP_SERVER_URL}comments/`, {
          text: this.newComment,
          photo_id: this.id
        }, {
          headers: Header.auth
        })
        this.photo_comments.push(response.data)
        this.newComment = ''
      } catch (error) {
      }
    },
    getImgUrl (url) {
      return `${process.env.VUE_APP_SERVER_URL}${url}`
    },
    closePhoto () {
      this.$parent.closePhoto()
      this.$router.go(-1)
    }
  }

}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_body {
  background-color: #292929;
  border-radius: 10px;
  width: 1105px;
  height: 856px;
  display: flex;
  flex-direction: column;
}
.modal_body_part_1{
  display:flex;
  flex-direction: row;
  width:100%;
  height: 100%;
}
.modal_body_left{
  display:flex;
  width:100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.modal_body_right{
  display:flex;
  flex-direction: column;
  width:400px;
  height: 100%;
  border-left: 1px solid rgba(130, 131, 138, 0.8);
}
.modal_body_right_header{
  height:66px;
  background-color: #49494E;
  border-radius: 0px 10px 0px 0px;
}

.modal_body_right_comments{
  height: 645px;
  overflow-y:scroll;
}
.modal_body_right_send{
  max-height: 80px;
  height: 100%;
  display:flex;
  align-items: center;
}
::-webkit-scrollbar {
  width: 6px; /* Ширина полосы скролла */
  height: 6px; /* Высота полосы скролла */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Цвет фона трека (неактивной области, вокруг полосы скролла) */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Цвет полосы скролла (активной области) */
  border-radius: 3px; /* Радиус скругления углов полосы скролла */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Цвет полосы скролла при наведении курсора мыши */
}
.comment_box{
  display:flex;
  align-items: center;
  justify-content: left;
  flex-direction: column;
  width:100%;
  min-height: 70px;
  color:white;
  font-family: 'mulish';
  margin-top:15px;
  padding:5px;
  background-color: #111114;
}
.comment_text {
 width:100%;
 align-items: center;
 display:flex;
 flex-direction: row;
}
.comment_date{
 width:100%;
 display:flex;
 font-size:10px;
}
.comment{
  width:230px;
  height: 60px;
  background-color: #292929;
  font-family: 'mulish';
  display: block;
  padding-top: 15px;
  margin: auto;
  color:white;
  resize: none;
  border:none;
}
.comment::placeholder {
  text-align: left;
}
.comment:focus{
  outline: none;
}
.user_photo{
  width:50px;
  border-radius: 25px;
}

.modal_description {
  position: relative;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  width:100%;
  height: 70px;
  border-top: 1px solid rgba(130, 131, 138, 0.8);
  text-align: left;
}
.modal_description p {
  margin-left:20px;
}
</style>
