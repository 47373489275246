<template>
<div class="list">
  <div class="field">
    <input id="search" class="search" type="text" placeholder="Поиск..." v-model="username">
    <select id="select" class="select" @click="get_all_users" v-model="select">
      <option value="false">Обычные пользователи</option>
      <option value="true">Заблокированные</option>
      <option value="all">Все пользователи</option>
    </select>
    <button id="add" type="button" class="btnAdd" @click="show_add_window(true)">Cоздать пользователя</button>
  </div>
  <div class="list__title">
    <div class="list__titleItem">Фото</div>
    <div class="list__titleItem">Логин</div>
    <div class="list__titleItem">Ф И О</div>
    <div id="list__titleItem">Дата последней операции</div>
    <div class="list__titleItem">Действия</div>
  </div>
  <div class="scroll">
    <a class="row" v-for="item in users.filter((user) => user.username.toLowerCase().includes(this.username.trim().toLowerCase()))" :key="item.id">
      <div class="usersInfo">
        <div class="usersInfo_line">
          <div class="col">
            <img id="photo" :src="url(item.photo_url)" alt="photo"/>
          </div>
          <div class="col">{{ item.username }}</div>
          <div class="col">{{ item.surname }} {{ item.name }} {{ item.patronymic }}</div>
          <div id="list__titleItem">{{ item.updated_at }}</div>
          <div class="btns">
            <button v-if="!item.is_blocked" id="bord_red" class="btn" @click="show_state_window(true, item)"></button>
            <button v-if="!item.is_blocked" id="bord_green" class="btn" @click="show_change_window(true, item)"></button>
            <button v-else id="bord_lite" class="btn" @click="show_state_window(true, item)"></button>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>

<!-- Окно создания пользователя -->
<div v-if="add_window" class="add add_container">
  <div class="add_body">
    <div class="add_close">
      <img id="close_1" src="@/assets/images/close.png" alt="close" @click="show_add_window(false)"/>
    </div>
    <p>Создание учетной записи</p>
    <div id="form" class="add_form">
      <div>
        <label>Логин:</label>
        <input-comp id="username" :value="user.username" :cl="'input_comp'" :message="error.username" @input="user.username = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Пароль:</label>
        <input-comp id="password" :type="'password'" :value="user.password" :cl="'input_comp'" :message="error.password" @input="user.password = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Имя:</label>
        <input-comp id="name" :value="user.name" :cl="'input_comp'" :message="error.name" @input="user.name = change_input($event.target.value)"></input-comp>
      </div>
      <div>
        <label>Фамилия:</label>
        <input-comp id="surname" :value="user.surname" :cl="'input_comp'" :message="error.surname" @input="user.surname = change_input($event.target.value)"></input-comp>
      </div>
      <div>
        <label>Отчество:</label>
        <input-comp id="patronymic" :value="user.patronymic" :cl="'input_comp'" :message="error.patronymic" @input="user.patronymic = change_input($event.target.value)"></input-comp>
      </div>
      <div>
        <label>Телефон:</label>
        <input-comp id="phone_number" :value="user.phone_number" :cl="'input_comp'" :message="error.phone_number" @input="user.phone_number = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Email:</label>
        <input-comp id="email" :value="user.email" :cl="'input_comp'" :message="error.email" @input="user.email = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Telegram:</label>
        <input-comp id="telegram_id" :value="user.telegram_id" :cl="'input_comp'" :message="error.telegram_id" @input="user.telegram_id = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Роль:</label>
        <select class="add_select" v-model="user.role_id">
          <option value="" disabled selected>Выберите из списка</option>
          <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.title }}</option>
        </select>
      </div>
      <div>
        <label>Направление:</label>
        <select class="add_select" v-model="user.direction_id">
          <option value="" disabled selected>Выберите из списка</option>
          <option v-for="direction in directions" :key="direction.id" :value="direction.id">{{ direction.title }}</option>
        </select>
      </div>
      <div class="add_checkbox">
        <div class="add_input">
          <label>Клиент</label>
          <input id="is_client" type="checkbox" v-model="user.is_client"/>
        </div>
        <div class="add_input">
          <label>Проверена ли учетная запись</label>
          <input id="is_verified" type="checkbox" v-model="user.is_verified"/>
        </div>
        <div class="add_input">
          <label>Администратор</label>
          <input id="is_superuser" type="checkbox" v-model="user.is_superuser"/>
        </div>
        <div class="add_input">
          <label>Работник</label>
          <input id="is_stuff" type="checkbox" v-model="user.is_stuff"/>
        </div>
      </div>
    </div>
    <button id="add" class="add_btn" @click="submit_user('add', user)">Создать</button>
  </div>
</div>

<!-- Окно редактирования пользователя -->
<div v-if="change_window" class="add add_container">
  <div class="add_body">
    <div class="add_close">
      <img id="close_1" src="@/assets/images/close.png" alt="close" @click="show_change_window(false)"/>
    </div>
    <p>Редактирование учетной записи</p>
    <div id="form" class="add_form">
      <div>
        <label>Логин:</label>
        <input-comp id="username" :value="temp_user.username" :cl="'input_comp'" :message="error.username" @input="temp_user.username = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Имя:</label>
        <input-comp id="name" :value="temp_user.name" :cl="'input_comp'" :message="error.name" @input="temp_user.name = change_input($event.target.value)"></input-comp>
      </div>
      <div>
        <label>Фамилия:</label>
        <input-comp id="surname" :value="temp_user.surname" :cl="'input_comp'" :message="error.surname" @input="temp_user.surname = change_input($event.target.value)"></input-comp>
      </div>
      <div>
        <label>Отчество:</label>
        <input-comp id="patronymic" :value="temp_user.patronymic" :cl="'input_comp'" :message="error.patronymic" @input="temp_user.patronymic = change_input($event.target.value)"></input-comp>
      </div>
      <div>
        <label>Телефон:</label>
        <input-comp id="phone_number" :value="temp_user.phone_number" :cl="'input_comp'" :message="error.phone_number" @input="temp_user.phone_number = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Email:</label>
        <input-comp id="email" :value="temp_user.email" :cl="'input_comp'" :message="error.email" @input="temp_user.email = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Telegram:</label>
        <input-comp id="telegram_id" :value="temp_user.telegram_id" :cl="'input_comp'" :message="error.telegram_id" @input="temp_user.telegram_id = $event.target.value"></input-comp>
      </div>
      <div>
        <label>Роль:</label>
        <select class="add_select" v-model="temp_user.role_id">
          <option value="" disabled selected>Выберите из списка</option>
          <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.title }}</option>
        </select>
      </div>
      <div>
        <label>Направление:</label>
        <select class="add_select" v-model="temp_user.direction_id">
          <option value="" disabled selected>Выберите из списка</option>
          <option v-for="direction in directions" :key="direction.id" :value="direction.id">{{ direction.title }}</option>
        </select>
      </div>
      <div class="add_checkbox">
        <div class="add_input">
          <label>Клиент</label>
          <input id="is_client" type="checkbox" v-model="temp_user.is_client"/>
        </div>
        <div class="add_input">
          <label>Проверена ли учетная запись</label>
          <input id="is_verified" type="checkbox" v-model="temp_user.is_verified"/>
        </div>
        <div class="add_input">
          <label>Администратор</label>
          <input id="is_superuser" type="checkbox" v-model="temp_user.is_superuser"/>
        </div>
        <div class="add_input">
          <label>Работник</label>
          <input id="is_stuff" type="checkbox" v-model="temp_user.is_stuff"/>
        </div>
      </div>
    </div>
    <button id="add" class="add_btn" @click="submit_user('change', temp_user)">Редактировать</button>
  </div>
</div>

<!-- Окно удаления / восстановления пользователя -->
<div v-if="state_window" class="state state_container">
  <div class="state_body">
    <div class="state_close">
      <img id="close_1" src="@/assets/images/close.png" @click="show_state_window(false)">
    </div>
    <p v-if="!temp_user.is_blocked">Удалить пользователя</p>
    <p v-else>Восстановить пользователя</p>
    <div class="state_btns">
      <button v-if="!temp_user.is_blocked" id="delete" @click="change_user(temp_user)">Удалить</button>
      <button v-else id="recover" @click="change_user(temp_user)">Восстановить</button>
      <button id="close_2" @click="show_state_window(false)">Закрыть</button>
    </div>
  </div>
</div>
</template>

<script>
import { validUsername, validPassword, validName, validSurname, validPatronymic, validNumber, validEmail, validTelegram } from '@/validation'
import { changeFioInput } from '@/converter'
import { getAllUsers, getAllRoles, getAllDirections } from '@/api/get'
import { postAddUser, postPhotoUser } from '@/api/post'
import { patchChangeUser, patchBlockUser, patchRecoverUser } from '@/api/patch'

export default {
  data () {
    return {
      add_window: false,
      change_window: false,
      state_window: false,
      username: '',
      select: 'false',
      users: [],
      roles: [],
      directions: [],
      temp_user: {},
      user: {
        username: '',
        password: '',
        name: '',
        surname: '',
        patronymic: '',
        is_verified: false,
        is_superuser: false,
        is_client: false,
        is_stuff: false,
        phone_number: '',
        email: '',
        telegram_id: '',
        role_id: '',
        direction_id: ''
      },
      error: {
        username: '',
        password: '',
        name: '',
        surname: '',
        patronymic: '',
        phone_number: '',
        email: '',
        telegram_id: ''
      }
    }
  },
  methods: {
    show_add_window (value) {
      this.add_window = value
      if (value) {
        this.get_all_roles()
        this.get_all_directions()
      }
    },
    show_change_window (value, user = {}) {
      this.temp_user = user
      this.change_window = value
      if (value) {
        this.get_all_roles()
        this.get_all_directions()
      }
    },
    show_state_window (value, user = {}) {
      this.state_window = value
      this.temp_user = user
    },
    get_all_users () {
      getAllUsers(this.select).then((response) => {
        this.users = response
      })
    },
    get_all_roles () {
      getAllRoles('false').then((response) => {
        this.roles = response
      })
    },
    get_all_directions () {
      getAllDirections('false').then((response) => {
        this.directions = response
      })
    },
    change_input (value) { return changeFioInput(value) },
    url (url) { return `${process.env.VUE_APP_SERVER_URL}/${url}` },
    submit_file () {
      const data = new FormData()
      data.append('image', event.target.files[0])
      postPhotoUser(this.user.id, data)
    },
    submit_user (value, user) {
      const err = this.error
      err.username = validUsername(user.username)
      err.password = validPassword(user.password)
      err.name = validName(user.name)
      err.surname = validSurname(user.surname)
      err.patronymic = validPatronymic(user.patronymic)
      err.phone_number = validNumber(user.phone_number)
      err.email = validEmail(user.email)
      err.telegram_id = validTelegram(user.telegram_id)
      if (!err.username && !err.name && !err.surname && !err.patronymic && !err.phone_number && !err.email && !err.telegram_id) {
        if (value === 'add' && !err.password) {
          postAddUser(user).then((value) => {
            if (value) {
              this.get_all_users()
              for (const key in this.user) {
                if (key in ['is_verified', 'is_superuser', 'is_client', 'is_stuff']) this.user[key] = false
                this.user[key] = ''
              }
              for (const key in this.error) this.error[key] = ''
              this.show_add_window(false)
            }
          })
        }
        if (value === 'change') {
          patchChangeUser(user).then((value) => {
            if (value) {
              this.temp_user = value
              this.get_all_users()
              this.show_change_window(false)
            }
          })
        }
        for (const key in this.error) this.user[key] = ''
      }
    },
    change_user (user) {
      if (user.is_blocked) {
        patchRecoverUser(user.id).then((response) => {
          if (response) {
            this.get_all_users()
            this.show_state_window(false)
          }
        })
      } else {
        patchBlockUser(user.id).then((response) => {
          if (response) {
            this.get_all_users()
            this.show_state_window(false)
          }
        })
      }
    }
  },
  mounted () {
    this.get_all_users()
  }
}
</script>
