import { createStore } from 'vuex'
import { cookies } from '@/api/index'
// import { getChatsList } from '@/api/get'
import { getChatsList, getOrganizationByUserId } from '@/api/get'

const store = createStore({
  namespaced: true,
  state: {
    DATA_USER: {},
    ROLE_URL: 'LkUser',
    LIST_USERS: [],
    LIST_APPEALS: [],
    LIST_ROLES: [],
    LIST_DIRECTIONS: [],
    LIST_ESTIMATES: [],
    LIST_DEPARTAMENTS: [],
    LIST_EMPLOYEES: [],
    LIST_COMMENTS: [],
    LIST_CHATS: [],
    LIST_CHATS_WS: [],
    ACTIVE_CHAT_MESSAGES: [],
    LIST_NOTIFICATIONS: []
  },
  actions: {
    setDataUser ({ commit }, data) {
      cookies.set('ID', data.id)
      this.state.DATA_USER = data
      // if (data.role_title === 'Администратор') {
      //   this.state.ROLE_URL = 'LkSysAdmin/Users'
      // } else if (data.role_title === 'Мастер') {
      //   this.state.ROLE_URL = 'LkMaster/UserMonitor'
      // } else if (data.role_title === 'Клиент') {
      //   this.state.ROLE_URL = 'LkUser'
      // }
    },
    logout ({ commit }) {
      cookies.keys().forEach((key) => {
        cookies.remove(key)
      })
      localStorage.clear()
      this.state.DATA_USER = {}
      this.state.ROLE_URL = 'LkUser'
    }
  },
  mutations: {
    connectChats (state) {
      if (cookies.get('ID')) {
        getOrganizationByUserId(cookies.get('ID'))
          .then(res => {
            getChatsList(false, res.organization_id, cookies.get('ID'))
              .then(response => {
                state.LIST_CHATS = response
                for (let i = 0; i < state.LIST_CHATS?.length; i++) {
                  const ws = new WebSocket(
                    // `wss://delevopers.ru/test_api/ws/chat/${state.LIST_CHATS[i].id}/${cookies.get('ID')}`
                    `${process.env.VUE_APP_SERVER_URL_CHAT_ACTIVE}${state.LIST_CHATS[i].id}/${cookies.get('ID')}`
                  )
                  ws.onopen = function () {
                    console.log('WebSocket connected chats')
                    // this.send('next')
                  }
                  ws.onerror = function (error) {
                    console.log('WebSocket error:', error)
                  }
                  ws.onclose = function () {
                    console.log('WebSocket closed chats')
                  }
                  ws.onmessage = (event) => {
                    const message = JSON.parse(event.data)
                    state.ACTIVE_CHAT_MESSAGES.unshift(message)
                  }
                  state.LIST_CHATS_WS.push(ws)
                }
              })
          })
      }
    },
    updateUserStatus (state, newStatus) {
      state.userStatus = newStatus
    }
  }
})

export default store
